
import { Auth, API } from 'aws-amplify';
import { DebounceQueue} from '../DebouncedQueue.js'

import * as moment from 'moment-timezone';

/** 
 * Define the interface for updating the clip review objects
 * @param {*} queue_key: which queue will be updated
 * @param {*} _data: the data collected by the action
 * @param {*} action: the action that was captured: play, stop, skip
 */
export const clipReviewUpdate = (queue_key, _data,action,_clipList, noDebounce=false)=>{
  // console.log("clip update:" ,action,_data);
  //Create a temporary object to send to the transmit queue
  let tmpObject = {key:_data.infractionid, username:_data.username};  
  let timestampFormat = "YYYY-MM-DD HH:mm:ss:SSS"; //define the timestamp format
  let currentTimestamp = moment().utc().format(timestampFormat); //get the current time
  //Load the timestamp into the object to send based on the current action that was observed
  switch(action){
    case 'play':{tmpObject.playstart=currentTimestamp };break;
    case 'stop':{tmpObject.playstop=currentTimestamp};break;
    case 'skip':{tmpObject.skip=currentTimestamp};break;
    case 'call':{tmpObject.call=currentTimestamp};break;
    case 'notecard':{tmpObject.notecard=currentTimestamp};break;
  }

  //Accumulate the time tracked by clip
  trackClipPlayback(_clipList,_data,action);

  //Define the merge rules that will be applied when the new data is added to the currently
  // cached data in the DebounceQueue. This is a callback triggered by the DebounceQueue
  const mergeRules = (_currentInteraction,_newData,_action)=>{
    // let mergedData = {..._oldData}; //copy the previous data to the merge structure
    // console.log("Rules:",_newData,_action)
    switch(_action){
      case 'play':{ //
        // console.log("Play already known? ",_currentInteraction.playstart);
      };break;
      case 'stop':{
        //Mark the end of viewing the current video: (may start playing again and will capture secondary end event)
        // if(!_currentInteraction.playstart){
        //   console.log("No recorded playstart on ", _currentInteraction)
        // }
        _currentInteraction.playstop = _newData.playstop;
        _currentInteraction.duration_vid = moment(_currentInteraction.playstop,timestampFormat).diff(moment(_currentInteraction.playstart,timestampFormat));
        _currentInteraction.status |= 0b001;
      };break;
      case 'skip':{
        //End of the current interaction
        _currentInteraction.skip = _newData.skip;
        _currentInteraction.duration_skip = moment(_currentInteraction.skip,timestampFormat).diff(moment(_currentInteraction.playstart,timestampFormat));
        _currentInteraction.status |= 0b010;
      };break;
      case 'call':{
        _currentInteraction.call = _newData.call;
      };break;
      case 'notecard':{
        _currentInteraction.notecard = _newData.notecard;
      };break;
    }
    return _currentInteraction;
  }

  //Send to the queue:
  if(!noDebounce){
    DebounceQueue.updateObject(queue_key,tmpObject,{callback: mergeRules, action: action});
  }
  
}

/**
 * Iterate through the list of assets to find the desired clip using the infractionid
 * @param {*} _clipList : 2D array of all clips indexed by assetid
 * @param {*} _infractionid : infractionid of the desired clip
 * @returns 
 */
const getClip=(_clipList,_infractionid)=>{
  let clipRef = null;
  try {
    //Check if a single asset's array is passed (will have .set) or 2d array of assets
    if(_clipList.set){
      let foundElem = _clipList.set.find(obj => {
          const infractionid = obj.infractionid || obj.InfractionID;
          return infractionid === _infractionid});
      //If found then stop iterating
      if(foundElem){ clipRef = foundElem;} //set the return value
    }else{
      //Iterate over the list of clips
      Object.keys(_clipList).forEach((asset_,index_) => {
        //Find the clip that matches the infractionid
        let foundElem = _clipList[asset_].set.find(obj => {
          const infractionid = obj.infractionid || obj.InfractionID;
          return infractionid === _infractionid});
        //If found then stop iterating
        if(foundElem){
          clipRef = foundElem; //set the return value
          return; //break out of the loop
        }
      })  
    }
  } catch (error) {console.log("Crash on getClip:" ,error);}
  
  return clipRef; //return the reference (shallow copy) to allow modification
}

/**
 * Update the internal time on a clip 
 * @param {*} _clipList : list of all clips indexed by the assetid
 * @param {*} _data : details of the current clip being watched
 * @param {*} action : action taken on the clip (play, stop, skip)
 * @returns 
 */
export const trackClipPlayback = (_clipList,_data,action)=>{
  //Get the clip from the set using the infractionig
  let clipRef = getClip(_clipList,_data.infractionid);
  // console.log("ClipREf:" ,clipRef,_clipList,_data);
  if(!clipRef){return;} //escape the tracking if no clip was found
  //Switch start/stop based on on the clip
  switch(action){
    case 'play':{ clipRef.timereviewed.start();};break; //start the timer
    case 'skip':
    case 'stop':{ clipRef.timereviewed.stop();};break; //stop the timer
  }
}