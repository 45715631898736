
import React, {  PureComponent } from 'react';
import './LiveClipPlayer.css';

import '../VideoReview/VideoClipPlayer.css';

import IconArrowPrev  from '../assets/arrowbox_prev.png';
import IconArrowNext  from '../assets/arrowbox_next.png';
import IconRepeat     from '../assets/repeat-icon_white.png';
/*
* @brief This is an expanded view on a Card, used on the HR Review page
*
* This view allows the user to see all comments on the card, and to add
* comments. It also allows viewing the video for the infraction in
* question.
*/

const ENABLE_PROFILE = false; 
const ENABLE_PROFILE_PLAY = false; 
const ENABLE_PLAYBACK_PROFILE = false;

export class LiveClipPlayer extends PureComponent {
    constructor(props) {
        super(props);
        this.onPlay = this.onPlay.bind(this);
        this.onPause = this.onPause.bind(this);
        this.onVideoPause=this.onVideoPause.bind(this);
        this.onReady = this.onReady.bind(this);
        
        this.onProgress = this.onProgress.bind(this);
        this.handleStop = this.handleStop.bind(this);
        //button skipping controls
        this.skipToClip = this.skipToClip.bind(this);
        this.skipNextClick = this.skipNextClick.bind(this);
        this.skipPrevClick = this.skipPrevClick.bind(this);

        
        this.overlayButtonClick = this.overlayButtonClick.bind(this);

        this.setCurrentTag = this.setCurrentTag.bind(this);
        this.shouldLoad = this.shouldLoad.bind(this);

        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.autoPlayVideo = this.autoPlayVideo.bind(this);
        this.windowResized = this.windowResized.bind(this);
        this.myTimer = this.myTimer.bind(this);
        
        this.refreshInterval = this.refreshInterval.bind(this);
        this.evaluateStyles = this.evaluateStyles.bind(this);
        this.renderControls = this.renderControls.bind(this);
        this.forceRefresh = this.forceRefresh.bind(this);
        
        // this.renderMap = this.renderMap.bind(this);
        this.onEnded = this.onEnded.bind(this);
        this.state = {
            loadCount: 0,
            loadedIDs: [],
            displayedInfraction:{},
            playerRef: [],
            infractionTags:{},
            playlistOrder: 0,
            readyID: null,
            playStates:[],
            firstLoad: true,
            prevOrder: null,
            bookmarkLoad: this.props.bookmarkLoad,
            ejectmaskStream:[],
            ejectmaskID:[],
            mLoadingWindowSize : 5,
            renderRefresh:0,
            isReady:[],
            timeSinceChange: new Date(),
            overlayClickTimer: null,
             //Define the default style for each button in the control set
            prevStyle: {visibility:"hidden"}, //default state is hidden
            nextStyle: {visibility:"hidden"}, //default state is hidden
        };
        this.perfName = 'ExpandedCard';
        // Define a single references for the <video> used on infraction clips
        this.singlePlayerRef =  React.createRef(null);
        this.lastUpdate = 0;
    }

     /*
    * @brief A handler for the event when the browser window is resized
    * Used to update the current window width, for scaling the page contents to fit
    */
     windowResized() {
      // console.log("Inner: ",window.innerWidth,window.innerHeight,window.screen.width,window.screen.height);
      // console.log("Doc: ",document.height, document.width, document.documentElement.clientWidth,document.documentElement.clientHeight);
      // console.log("Body: ",document.getElementsByTagName('body')[0].clientWidth, document.getElementsByTagName('body')[0].clientHeight);


      this.setState({
        winWidth: document.documentElement.clientWidth - 40,
        winSize : {
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight
        }
      })
    }
    myTimer() {
      if(this.props.chosen !== this.props.name){
      }else{
         console.log("Click me: ",this.state.displayedInfraction.assetid)
        // this.overlayButtonClick();
      }
      
    }
    /* @brief Force refresh of the displayedInfraction, and trigger the overlayButtonClick
    */
    forceRefresh(){
      console.log("Refresh requested? ",this.props.name);

      // this.props.currentClip.set[this.state.playlistOrder]
      this.setState(
        prevState => {
          let infractionTags = prevState.infractionTags;
          if(!infractionTags.current){ infractionTags.current = []; }
          infractionTags.current[this.props.currentClip.chosenName]= this.props.currentClip.set[prevState.playlistOrder];
      
          let displayedInfraction = {};
          if(infractionTags && infractionTags.current){
              displayedInfraction = infractionTags.current[this.props.currentClip.chosenName] || {};
          }
          return{tagsreviewed: prevState.tagsreviewed+1, displayedInfraction: displayedInfraction, infractionTags: infractionTags}
        },
          () => {   
          this.overlayButtonClick();  
          }
      );
    }
   
    /* @brief Called one time on load of the lcass, set the initial displayed infraction state.
    */
    componentDidMount() {
      // console.log("Mount triggered on: ",this.props.name);
       //Use this playlist order if we are showing a set of clips:
       let displayedInfraction = {};
       if(this.props.currentClip && this.props.currentClip.type==='playlist'){          
        displayedInfraction = this.props.currentClip.set[this.state.playlistOrder];
        this.setCurrentTag(this.props.currentClip.set[this.state.playlistOrder]);
      }
      window.addEventListener("optimizedResize", this.windowResized);
      this.windowResized();

      
      // this.setState({overlayClickTimer:setInterval(this.myTimer, 250)});
      // this.setState({overlayClickTimer:setInterval(this.myTimer, 1000)});
    }

    /* @brief Run once when the class is leaving
    */
    componentWillUnmount(){
      window.removeEventListener("optimizedResize", this.windowResized);
      if(this.state.overlayClickTimer){clearInterval(this.state.overlayClickTimer);}
      if(this.refreshedTimer)(clearInterval(this.refreshedTimer));
    }

    /* @brief Listen for a change to the states, update play state if playcount changes
    */
   componentDidUpdate(newProps) {
    //  return;
    // UNSAFE_componentWillReceiveProps(newProps) {
      if (JSON.stringify(this.props) !== JSON.stringify(newProps)){
        
        //Check if the displayed infraction has been set up, if not yet then do it here, instead of render loop
        //Shouldn't be doing state updates from the render loop
        if(!this.state.displayedInfraction.infractionid){
          if(newProps.currentClip && newProps.currentClip.count > 0 ){
            // console.log("New props?", newProps.currentClip.count, this.props.currentClip.count, this.props.name);    
            this.setCurrentTag(this.props.currentClip.set[this.state.playlistOrder]);
          }
        } 

        if((new Date() - newProps.timeSinceRefresh)<500){
          console.log("Need to force the update?")
        }
        if(ENABLE_PROFILE_PLAY){window.performance.mark(this.props.name+'_ActiveKeySet');}
        // window.performance.mark('ActiveKeySet');
        if(this.props.chosen !== newProps.chosen ){
          //  console.log("Triggered chosen update",newProps,this.props)
          if(this.props.chosen !== this.props.name){ //check if the current asset is selected.
            // console.log("Turn off? ",this.props.name);
            this.setState(prevState => {
              const clonedStates = Object.assign({}, prevState.playStates);
              if(clonedStates[this.state.playlistOrder]){
                // console.log("Not selected, shutdown ",this.props.name,this.props.chosen)
                try {
                  
                  if(this.singlePlayerRef.current){
                    this.singlePlayerRef.current.currentTime = 0;
                  }
                } catch (error) {
                }
              }
              delete clonedStates[this.state.playlistOrder];
              return {playStates: clonedStates,mLoadingWindowSize:5};
            });
          }else{
            //asset selected: autoplay:
            // console.log("Set autoplay ",this.props)
            if(ENABLE_PLAYBACK_PROFILE){
              if(this.props.chosen === this.props.name){
                window.performance.mark(this.props.name+'_PlaybackStart');
              }
            }
            this.setState(prevState => {
              // console.log("Asset selected, autoplay: ",prevState.playlistOrder)
              const clonedStates = Object.assign({}, prevState.playStates);
              clonedStates[prevState.playlistOrder] = true;
              // console.log("Cloned states:" ,clonedStates);
              return {playStates: clonedStates,mLoadingWindowSize:5, timeSinceChange: new Date()};
            },
            //Force playback start
            () => {   this.autoPlayVideo();   }
            );
            
          }
        }
        //check if we changed memory handling:
        if(this.props.bMemoryOptimized !== newProps.bMemoryOptimized ){
          console.log("Memory handling updated ",this.props.bMemoryOptimized,newProps.bMemoryOptimized)
          if(this.props.chosen === this.props.name){
            this.setState({mLoadingWindowSize:25})
          }
        }
        if(this.props.loadCount < newProps.loadCount ){
          // console.log("ReOrder processed");
          this.skipToClip(0,this.state.playlistOrder); //skip back to the start of the clips
        }

      }
    }

    /* @brief Actions to apply to the player when the component unmounts
    */
    handleStop(){
      // console.log("Stop: ",this.props.currentClip.chosenName);
      //loop through the player references: get the internal player 
      //and set the src url to null, trigger a reload to force the URL update
      try{
        if(this.singlePlayerRef.current){
          this.singlePlayerRef.current.src =null;
          this.singlePlayerRef.current.load();
        }
      }catch(e){
        console.log("Failed to trigger player stop? ",e);
      }
      this.setState({playerRef: {}});
    }

    /* @brief Notify the player that a new clips is available and needs to be updated
    */
    findUnHiddenClip(_data){
      try {
        if(this.props.currentClip.type === 'playlist'){ 
          // console.log("Find unhidden for: ",this.props.currentClip.chosenName, this.state.playlistOrder, this.state.ejectmaskID);
          //Find the clip that isn't hidden?
          let newVal = this.state.playlistOrder;
          while(this.state.ejectmaskID.includes(newVal)){
            newVal +=1;
          }
          //Set the clip number to the first that is visible: (dont autoplay the clip)
          this.skipToClip(newVal,this.state.playlistOrder,true);
        }  
      } catch (error) {
      }
    }

  /* Receive callback when the video playback ends*/
  onEnded(){
    if(this.props.chosen === this.props.name){
      // console.log("Stopped playback?",this.props.currentClip.set[this.state.playlistOrder].timereviewed.getTime())
      // this.setState({refreshedTimer: new Date()});
      this.evaluateStyles();
      let data = {}
      try{
        let currentClip = this.props.currentClip.set[this.state.playlistOrder];
        data = {  infractionid: currentClip.infractionid,
                  chosenName: this.props.name,
                  clientid: this.props.clientid,  
                  highlighted: this.props.chosen,                              
                }
      }catch(e){}
      //Send the update to the LiveTab
      if(this.props.onStop){this.props.onStop(data);}
    }
  }
  onReady(_data){
    //  console.log("OnReady: ",this.props.name,this.state.firstLoad,this.props.currentClip.set[this.state.playlistOrder] ,this.state.playlistOrder);
    //Relocate from the render() loop to prevent the warning message
    if(this.props.chosen === this.props.name){ //only do the mark view callback on the currently chosen video?
      let data = {}
      try{
        let currentClip = this.props.currentClip.set[this.state.playlistOrder];
        data = {  meta: currentClip,
                  chosenName: this.props.name,
                  clientid: this.props.clientid,  
                  highlighted: this.props.chosen,                              
                }
        //Save that the clip is loaded and ready to play
        this.setState({readyID:this.props.currentClip.set[this.state.playlistOrder].infractionid});
      }catch(e){}                    
      data.meta.Tag = this.state.playlistOrder.toString();
      data.meta.viewed = true;
      data.complete = !this.isHidden(1);
      if(this.props.markViewed){this.props.markViewed(data);}
      
      if(ENABLE_PROFILE){window.performance.mark('OtherEnd');}
      if(ENABLE_PROFILE){window.performance.measure("Other Timing", 'Other', 'OtherEnd');}
      
    }

    try {
      if(ENABLE_PLAYBACK_PROFILE){
        if(this.props.chosen === this.props.name){
          window.performance.measure("StartPlayAfterSkip",this.props.name+'_PlaybackStart');
          // console.log(performance.getEntriesByType("measure"));
          console.log("Start playback: ",window.performance.getEntriesByName('StartPlayAfterSkip', 'measure')[0].duration);
          window.performance.clearMarks();
          window.performance.clearMeasures();
        }
      }    
    } catch (error) {
       console.log("Failed to get time?",error);
    }

    if(this.state.firstLoad){  
      return;
    }
    

    if(this.props.currentClip.streamURL === 'playlist'){
        this.setState(prevState => {
          const clonedStates = Object.assign({}, prevState.playStates);
          clonedStates[prevState.playlistOrder] = true;
          return {playStates: clonedStates};
        });
    }
    
  }
   /* Receive callback when the video is loaded and ready to play*/
  onPlay(_data){
    if(this.props.chosen === this.props.name){
      // console.log("Started playing the video?",this.props.currentClip.set[this.state.playlistOrder].infractionid,this.props.currentClip.set[this.state.playlistOrder].timereviewed.getTime())
      this.refreshInterval();
      let data = {}
      try{
        let currentClip = this.props.currentClip.set[this.state.playlistOrder];
        data = {  infractionid: currentClip.infractionid,
                  timerecorded: currentClip.timerecorded, //time of the clip (at the asset timezone)
                  chosenName: this.props.name,
                  clientid: this.props.clientid,  
                  highlighted: this.props.chosen,                              
                }
      }catch(e){}                    
      //Send the update to the LiveTab
      if(this.props.onPlay){this.props.onPlay(data);}
    }
    
    // if(this.state.firstLoad){
    //  window.setTimeout(() => { this.setState({firstLoad:false}) }, 100);
    // }
  }
  /* Receive callback when the video playback is paused*/
  onVideoPause(){
    let data = {}
    try{
      let currentClip = this.props.currentClip.set[this.state.playlistOrder];
      data = {  infractionid: currentClip.infractionid,
                chosenName: this.props.name,
                clientid: this.props.clientid,  
                highlighted: this.props.chosen,                              
              }
    }catch(e){}                    
    //Send the update to the LiveTab to stop the review timer
    if(this.props.onStop){this.props.onStop(data);}
  }
  /**
   * Callback triggered by control function to pause the video, used when a notecard is created
   */
  onPause(){
    // console.log("onPause triggered")
    // if(this.refreshedTimer){clearInterval(this.refreshedTimer)}
    if(this.props.currentClip.streamURL === 'playlist'){      

      let currentVal = this.state.playlistOrder;
      // console.log("OnPause called: ", currentVal,this.state.prevOrder)
      if(!this.state.prevOrder){ //needs to handle the case when skipping backwards, without the video won't autoplay
        this.setState(prevState => {
          const clonedStates = Object.assign({}, prevState.playStates);
          delete clonedStates[currentVal];
          return {playStates: clonedStates, prevOrder:null};
      });
      }else{
        this.setState({prevOrder:null}) ;
      }        
    }
   
  }

  /* Special call back to force the single player <video> tag to autoplay the video*/
  autoPlayVideo(){
    if(!this.props.currentClip){return;}
    // return;
    try {
      // console.log("AutoPlay: ",this.props);
      if(this.props.currentClip.streamURL === 'playlist'){  //limit to only the infraction clips, not the highlight videos
        //  console.log("AutoPlay infraction?");
        if(this.props.chosen === this.props.name){//not hidden      
          // let shouldPlay = (this.state.playStates[this.state.playlistOrder] || false) ;
          // console.log("Play? ",shouldPlay, this.state.playStates);
          // if(shouldPlay && this.singlePlayerRef.current){
          if(this.singlePlayerRef.current){

            try {
              this.singlePlayerRef.current.load(); //Force a reload, needed when using the <source> element with the <video> element  
            } catch (error2) {
              console.log("Failed on autoplay load:" ,error2);
            }
            
            var playPromise = this.singlePlayerRef.current.play();
            if (playPromise !== undefined) {
              playPromise.then(_ => {})
              .catch(error => {
                // Auto-play was prevented
                setTimeout(() => {  this.autoPlayVideo();   }, 500); //back off so that the error can propagate, otherwise we will just loop
              });
            }
          
          }
        }else{}
      }  
    } catch (error) {
      console.log("Failed on autoplay:" ,error);
    }
    
  }

 
  
 
  /* periodically trigger a re-eval of the hidden styles, to address cases of the no render updates (paused)*/
  refreshInterval(){
    if(this.refreshedTimer){clearInterval(this.refreshedTimer)}
    this.refreshedTimer = setInterval(()=>{
      // console.log("Interval triggered:" ,this.props.currentClip.set[this.state.playlistOrder].timereviewed.getTime())
      this.evaluateStyles();       
    }, 1000);
  }      

  

  /* Receives progress callbacks from the video, when it is playing or seeked */
  onProgress(progress){
    // this.setState({refreshedTimer: new Date()});
    if(!progress || !progress.timeStamp){return;}    
    //Extract the seconds from the timestamp, 
    // trigger an update to the render/evalutation every two seconds
    let currentTime = (progress.timeStamp/1000|0)%2;
    if(currentTime != this.lastUpdate && currentTime>0){
      this.refreshInterval();
      // console.log("OnProgress: ",progress.timeStamp)
      this.evaluateStyles();
    }
    this.lastUpdate = currentTime;
    return;    
    
  }//end onProgress

  /* Limit the number of clips based on a windows around the current index */
  shouldLoad(idx,_iCount){
    if(ENABLE_PROFILE){window.performance.mark('ShouldLoad');}
    
    //count values before current index
    try {
      let iCount = 0;    
      let playlistLocation = this.state.playlistOrder;
      // console.log("Start:", playlistLocation)
      //Look back
      while(iCount < _iCount && playlistLocation >= 0){
        // console.log("Test: ",playlistLocation,idx,iCount)
        //Don't count the ejected clips:
        if(!this.state.ejectmaskID.includes(playlistLocation)){iCount++;}      
        playlistLocation--;
      }
      if( idx < playlistLocation && playlistLocation >= 0){
        // if(this.props.chosen === 'TK501'){console.log("No Show bw: ",playlistOrder,playlistLocation,idx)}
        return false;
      }
      //Look forward
      iCount =0;
      playlistLocation = this.state.playlistOrder;
      while(iCount < _iCount && playlistLocation < this.props.currentClip.set.length){
        // console.log("Test: ",playlistLocation,idx,iCount)
        //Don't count the ejected clips:
        if(!this.state.ejectmaskID.includes(playlistLocation)){iCount++;}              
        playlistLocation++;
      }
      if(idx > playlistLocation){
        //  if(this.props.chosen === 'TK501'){console.log("No Show fw: ",playlistOrder,playlistLocation,idx)}
        return false;}
      
      // if(this.props.name === 'TK501' ){console.log("Show: ",playlistOrder,playlistLocation,idx,this.props.currentClip.set.length)}
      return true;
      
    
    } catch (error) {
      console.log("Failed on load check")
      return true;
    }
    finally{
      if(ENABLE_PROFILE){ window.performance.mark('ShouldLoadEnd');}
      if(ENABLE_PROFILE){window.performance.measure("ShouldLoad Time", 'ShouldLoad', 'ShouldLoadEnd');}
    }
    
  }

    /* @brief helper method to find the infraction id in the current set of clips based on the index order
    */
    getInfractionID(_currentClip,_position){
      let infractionID = 0;
      try {
        // console.log("From set: ",_currentClip); 
        infractionID = _currentClip.set[_position].infractionid;
      } catch (error) {
        // console.log("Failed to get infractionid: ",error); 
      }
      return infractionID;
    }


  isHidden(_direction){
    try {
      if(_direction>0){
        let newVal =this.state.playlistOrder+1;
        //  console.log("Testing : ",newVal,this.props.currentClip.set[newVal].infractionid,this.state.isReady);
        // while(this.state.ejectmaskID.includes(newVal) || !this.state.isReady.includes(this.props.currentClip.set[newVal].infractionid) ){
        while(this.state.ejectmaskID.includes(newVal)){
          // console.log("Had to skip: ",newVal);
          newVal +=1;
        }
        // console.log("Hidden: ",this.props.name,newVal, this.props.currentClip.count, this.props.currentClip.set.length)
        // if(newVal <this.props.currentClip.count){
        if(newVal < this.props.loadCount){
          // console.log("Return true: ",newVal,this.props.loadCount,this.props.currentClip.count)
          return true;
        }
        return false;
      }else{
        let newVal =this.state.playlistOrder-1;
        // while(this.state.ejectmaskID.includes(newVal) || !this.state.isReady.includes(this.props.currentClip.set[newVal].infractionid) ){
         while(this.state.ejectmaskID.includes(newVal) ){
          // console.log("Had to skip: ",newVal);
          newVal -=1;
        }
        if(newVal >=0){
          return true;
        }
        return false;
      }  
    } catch (error) {
      // console.log("Caught on error?",error)
      return false;
    }
    
  }

  /* Helper method to return the current tag from the metadata*/
  setCurrentTag(_currentTag){
    // this.props.markViewed(this.props.currentClip.chosenName,_currentTag);
    // console.log("Set current tag called",_currentTag);
    
    this.setState(
      prevState => {
        let infractionTags = prevState.infractionTags;
        if(!infractionTags.current){ infractionTags.current = []; }
        infractionTags.current[this.props.currentClip.chosenName]= _currentTag;
    
        let displayedInfraction = {};
        if(infractionTags && infractionTags.current){
            displayedInfraction = infractionTags.current[this.props.currentClip.chosenName] || {};
        }
        return{tagsreviewed: prevState.tagsreviewed+1, displayedInfraction: displayedInfraction, infractionTags: infractionTags}
      }              
    );
  }  

  skipToClip(_number,_oldNumber, _dontPlay){
    if(this.props.currentClip.streamURL !== 'playlist' && !this.props.currentClip.set){return;}

    if(ENABLE_PLAYBACK_PROFILE){
      if(this.props.chosen === this.props.name){
        window.performance.mark(this.props.name+'_PlaybackStart');
      }
    }

    if(_number >= 0 && _number <this.props.currentClip.set.length){
      // console.log("Skip to clip")
      this.setCurrentTag(this.props.currentClip.set[_number]);
      let internalPlayer =null;
      try {
        // console.log("Skip to clip 2 ",this.state.playerRef)
        // this.state.playerRef[_number].seekTo(0,'seconds'); //reset the tagged infraction clips to the start of video
        //  this.state.playerRef[_oldNumber].seekTo(0,'seconds'); //reset the tagged infraction clips to the start of video
        if(this.singlePlayerRef.current){
          this.singlePlayerRef.current.currentTime = 0; //reset the tagged infraction clips to the start of video
        }
         
        
        // console.log("Skip to clip ",this.state.playerRef[_number])
        // let internalPlayer =this.state.playerRef[_number].getInternalPlayer();
        //    // console.log("player src: ",internalPlayer.src);
        //    console.log("Player: ",internalPlayer);
        //    internalPlayer.className = 'test1';
        //   //  internalPlayer.load();

        // internalPlayer=this.state.playerRef[_number].getInternalPlayer();
        // internalPlayer.play();

      } catch (error) {
        console.log("Error: ",error,internalPlayer);
      }
      // this.setState({playlistOrder:_number});
        // console.log("Skip to clip: ",_number,_oldNumber, this.props.name);
      this.setState(prevState => {
        if(_dontPlay){
          return {playlistOrder:_number};
        }else{
          const clonedStates = Object.assign({}, prevState.playStates);
          if(clonedStates[_oldNumber]){
            delete clonedStates[_oldNumber];
          }
          clonedStates[_number] = true;
          return {playStates: clonedStates,playlistOrder:_number};
        }
      },
      //Force playback start
      () => {   this.autoPlayVideo();   }
      );
    }
  
  }

   /* @brief Handle the skip to next clip button press
  */
  skipNextClick(){
    try {
        // console.log("SKip clicked");
        if(this.props.currentClip.streamURL === 'playlist'){
          if(ENABLE_PROFILE_PLAY){window.performance.mark(this.props.name+'_ActiveKeySet');}
          let newVal =this.state.playlistOrder+1;
          while(this.state.ejectmaskID.includes(newVal)){
            // console.log("Had to skip: ",newVal);
            newVal +=1;
          }
          let currentVal =this.state.playlistOrder;
          this.skipToClip(newVal,currentVal);
      
          if(this.props.chosen === this.props.name){
            let data = {}
            try{
              let currentClip = this.props.currentClip.set[this.state.playlistOrder];
              data = {  infractionid: currentClip.infractionid,
                        chosenName: this.props.name,
                        clientid: this.props.clientid,  
                        highlighted: this.props.chosen,                              
                      }
            }catch(e){}
            //Send the update to the LiveTab
            if(this.props.onSkip){this.props.onSkip(data);}
          }
          
        }        
    } catch (error) { 
      console.log("Skip next error: ",error)  
    }
  }
    /* @brief Handle the skip to previous clip button press
    */
  skipPrevClick(){
    try{
        if(this.props.currentClip.streamURL === 'playlist'){
          // console.log("SkipPrev Enter states: ",this.state);
            let newVal =this.state.playlistOrder-1;
            while(this.state.ejectmaskID.includes(newVal)){
              // console.log("Had to skip: ",newVal);
              newVal -=1;
            }
            let currentVal = this.state.playlistOrder;
            // console.log("PrevClick new,curr: ",newVal, currentVal);
            this.setState({prevOrder:currentVal});
            this.skipToClip(newVal,currentVal);

            if(this.props.chosen === this.props.name){
              let data = {}
              try{
                let currentClip = this.props.currentClip.set[this.state.playlistOrder];
                data = {  infractionid: currentClip.infractionid,
                          chosenName: this.props.name,
                          clientid: this.props.clientid,  
                          highlighted: this.props.chosen,                              
                        }
              }catch(e){}
              //Send the update to the LiveTab
              if(this.props.onSkip){this.props.onSkip(data);}
            }
        }
        
      
    } catch (error) {   }
  }
 

overlayButtonClick(){
  // console.log("Button click: ",this.state,this.props)
  try {
    let disableButton = false;
    (this.props.filter.role || []).forEach(role=>{
      if(role==='SiteManager'){disableButton=true;}
    })
    if(disableButton){return;}
    let details = {
      chosenName : this.props.chosen,
      assetid: this.state.displayedInfraction.assetid,
      classification: this.state.displayedInfraction.classification,
      Tag : this.state.displayedInfraction.Tag || this.state.playlistOrder.toString(),
      infractionID : this.state.displayedInfraction.infractionid,
      type: this.props.currentClip.type,
      id: this.state.displayedInfraction.id,
      clientid: this.props.clientid,
      timerecorded: this.state.displayedInfraction.timerecorded,
      // timeClicked: new Date(),
    }
    // console.log("ButtonClick details: ",details);
    this.onPause();
    
    this.props.overlayButtonClick(details);  
  } catch (error) {
    console.log("Overlay error: ",error); 
  }
  
 
} //end overlay button click

      
    /*
    * @brief Render the content of the card:
    */
    render() {
      if(ENABLE_PROFILE){ window.performance.mark('Render');}
      
      const hidden = (this.props.chosen !== this.props.name) 
      let hiddenClass = hidden ? "hidden-video" : "";      
      
      if(this.props.loadCount <=0 || !this.props.currentClip){ //don't render the empty video player
        // if(this.props.chosen === 'TK502'){
        //   console.log("This: ",this.props,this.state,this.props.loadCount <=0,!this.props.currentClip);
        // }  
        hiddenClass = "hidden-video"
        return (
          <div></div>
        )
      }

      //Catch cases where the set is empty, or net set ( this could case a crash if the 0 element is attempted)
      if(!this.props.currentClip.set || this.props.currentClip.set.length === 0 ){
        console.log("Empty set? ",this.props.currentClip.set)
        return (
          <div></div>
        )
      }
      
      //Make sure the displayedInfraction is set when a new clip is added
      let displayedInfraction = this.state.displayedInfraction;
      if(!displayedInfraction.infractionid && this.props.currentClip && this.props.currentClip.count > 0 ){
        if(this.props.currentClip.type==='playlist'){          
          // console.log("set current clip: ",this.props.currentClip)
          // this.setCurrentTag(this.props.currentClip.set[this.state.playlistOrder]);
          return (
            <div></div>
          )
        }
      }


      let overlayButton =null;

      if(ENABLE_PROFILE){ window.performance.mark('Players');}
          let players = this.players||[];
        //Try catch to handle grabbing the current clip from the set and passing to the <video> player
        try {

          if(this.props.currentClip && this.props.currentClip.streamURL === 'playlist'){
              let playerConfig = {
                attributes: { preload: 'auto' },
                forceVideo: true,
              };

              let URLToLoad= null;
              //Load the current clip to paly from the set, using the tracked index (updated by the skip commands)
              let currentClip = this.props.currentClip.set[this.state.playlistOrder];
          
                if(this.props.chosen === this.props.name){
                  // console.log("Loaded? ",idx,this.state.playlistOrder, this.props.currentClip.set, this.props.loadedClips)
                }

                //loaded element is returned from the database after adding/modifying the record
                let loadedElem = (this.props.loadedClips || []).filter(data_ =>{return data_.infractionid === currentClip.infractionid});
                loadedElem = loadedElem[0];
                if(!loadedElem){ //if not found, then create a temporary
                  loadedElem = {
                    blob: null,
                    streamURL: currentClip.streamURL,
                    infractionid : currentClip.infractionid,
                    assetid: currentClip.assetid,                    
                  }
                }else{
                  // console.log("Found the clip: ",loadedElem);
                }
                //Limit the loading of many clips, if the current index is more than X from the load count, do not proceed.
                //Handle loading the files, if we have sufficient memory, download them directly
                if(!URLToLoad)
                {
                    //Load the local memory, or fallback to the URL if not avialable
                    if(loadedElem.blob && loadedElem.blob!== 'pending'){
                      URLToLoad = loadedElem.blob;
                    }else{
                      URLToLoad = loadedElem.streamURL;
                    }                        
                }
                
                if(ENABLE_PROFILE){window.performance.mark('Other');}

                if(currentClip.streamURL.substring(0,4)==='blob'){ //If this is a local blob file, then set this to force video:
                  playerConfig={
                    file: {
                        attributes: { preload: 'auto' },
                        forceVideo: true,
                        // forceDASH: true,
                    }
                  }
                }
                // Call markViewed to report that the video was reviewed 
                // if(this.props.chosen === this.props.name){ //only do the mark view callback on the currently chosen video?
                //   let data = {}
                //   try{
                //     data = {  meta: currentClip,
                //               chosenName: this.props.name,
                //               clientid: this.props.clientid,  
                //               highlighted: this.props.chosen,                              
                //             }
                //   }catch(e){}                    
                //   data.meta.Tag = this.state.playlistOrder.toString();
                //   data.complete = !this.isHidden(1);
                //   // // console.log("Send current playing : ",data);
                //   // this.props.markViewed(data);
                  
                //   if(ENABLE_PROFILE){window.performance.mark('OtherEnd');}
                //   if(ENABLE_PROFILE){window.performance.measure("Other Timing", 'Other', 'OtherEnd');}

                // }
                if(ENABLE_PROFILE){window.performance.mark('ReactPlayer');}

                if(ENABLE_PROFILE){window.performance.mark('ReactPlayerEnd');}
                if(ENABLE_PROFILE){window.performance.measure("React Player", 'ReactPlayer', 'ReactPlayerEnd');}


                if(ENABLE_PROFILE){window.performance.mark('IterationEnd');}
                if(ENABLE_PROFILE){window.performance.measure("IterationEnd timing", 'Iteration', 'IterationEnd');}
                  

              let bDisableButton =  false;
              //Set the button as disabled if clip is ejected:
              if(this.state.ejectmaskID.includes(this.state.playlistOrder)){
                bDisableButton = true;
              }
              overlayButton =  <button className="overlay-button"  disabled={bDisableButton} onClick={this.overlayButtonClick}> {this.state.playlistOrder} </button>;

              
              let infractionid = this.getInfractionID(this.props.currentClip,this.state.playlistOrder);
              // console.log("Notecards: ",this.props.notecards, infractionid);
              if(this.props.notecards && this.props.notecards[infractionid] ){
                // console.log("Notecard found for ", elem_.infractionid)
                overlayButton =  <button className="overlay-button"  disabled={bDisableButton} data-card="true" onClick={this.overlayButtonClick}> {this.state.playlistOrder} </button>;
              }

              if(this.props.chosen !== this.props.name){
                overlayButton = null;
              }

               //Configure a fixed size window for the video playback:
                // let playerSize = {width: 45, height: 62.595};
               let playerSize = {width: 630, height: 425};
               try {
                 
                 if(this.state.winSize.width <1300){
                  playerSize.width = 572;
                  playerSize.height = 381;
                 }else{
                  playerSize.width = Math.max(this.state.winSize.width *0.45,500);
                  playerSize.height = playerSize.width *.66;
                 }
                 
                //  console.log("Window: ",this.state.winSize.width, widthVal);
               } catch (error) {
                //  console.log("Windows error: ",error);
               }

               if(this.props.chosen === this.props.name){
                //  console.log("Play clip:",this.props.name, this.props.chosen,this.state.playlistOrder,loadedElem)
               }

               let className = 'fixed-size'; //define the classnames to apply to the video clip player
               //Parse the stream name to check if the clip was triggered by the Remote command function
               let isRemote = this.isRemoteCommandClip(this.props.currentClip.set[this.state.playlistOrder]);
               let remoteType = null;
              if(isRemote){ 
                className += " remote-clip" 
                remoteType = this.getTypeName(this.props.currentClip.set[this.state.playlistOrder]);
              } //add a class to mark that this is a remote triggered clip
               //Define the play to show the video
               players=   <fieldset className={className} style={{"width" : playerSize.width+'px',"height":playerSize.height+'px'}}>
                            {remoteType&&<div className={"cliptitle"}><div>{remoteType}</div></div>}
                            <video className={className}
                                ref = {this.singlePlayerRef}
                                preload="auto"
                                muted="muted"
                                controls
                                width = {playerSize.width+'px'}
                                height = {playerSize.height+'px'}
                                onError={(e)=>{
                                  console.log("Error on video: ",this.props.name,e,URLToLoad)
                                  this.skipNextClick(); //automatically try the next available clip if this clip failed to load
                                }}
                                onCanPlay={this.onReady}
                                onEnded={this.onEnded}
                                onPlay={this.onPlay}
                                onPause={this.onVideoPause}
                                onTimeUpdate={this.onProgress}                               
                                //Remove the options from the controls toolbar 
                                controlsList="noplaybackrate nodownload" //block the download and the playback rate
                                disablePictureInPicture={true} //remove the pip control 
                                onRateChange={()=>{this.singlePlayerRef.current.playbackRate =1;}}
                              >
                              {/* The source must contain the explicit type of video to work in Safari */}
                              <source src= {URLToLoad} type="video/mp4"></source>
                            </video>
                          </fieldset>
                        
                if(ENABLE_PLAYBACK_PROFILE){
                  if(this.singlePlayerRef.current){
                    this.singlePlayerRef.current.addEventListener("play",this.onPlay);
                    
                  }
                }
                
              this.players = players;

          } //end cehck if clips is loaded as playlist

        } catch (error) {
          console.log("Failed to load video: ", this.props.name, error);//, this.state, this.props);    
          return (
            <div></div>
          )
        }


          if(ENABLE_PROFILE){window.performance.mark('PlayersEnd');}
          if(ENABLE_PROFILE){window.performance.measure("HandlePlayers", 'Players', 'PlayersEnd');}
        

        if(ENABLE_PROFILE){window.performance.mark('RenderEND');}
        if(ENABLE_PROFILE){window.performance.measure("RenderUpdate", 'Render', 'RenderEND');}

       
        
        
        const res =(
        <div className={"live-reviewer-player" + " " + hiddenClass}
                    key={"player-"}>
            {players}
            
            {overlayButton}
            {this.renderControls(hidden)}
        </div>);
        return res;
    }
    
  getClipType(_clipDetails){
    // Sample format of streamURL
    // https://d15n52xej5c0ab.cloudfront.net/devgroup/e3b_results/b9904c73-68f2-40a7-b812-f80ca1dbabab/highlights/059850Manual.mp4.....?
    
    try {
      //Parse the download url of the clip, need to discard everything but the filename to prevent accidental matches on other strings
      let clipName = _clipDetails.streamURL;
      clipName = clipName.split(".mp4")[0]; //split at the .mp4 to discard the remainder of the string
      clipName = clipName.split("/").pop(); //only look at the file name after the last /
      return clipName;
    } catch (error) {
      // console.log("Error on clip name extract: ",error,_clipDetails);
      return null; //on failure assume no match
    }
  }//end getClipType
  getTypeName(_clipDetails){
    // Sample format of streamURL
    // https://d15n52xej5c0ab.cloudfront.net/devgroup/e3b_results/b9904c73-68f2-40a7-b812-f80ca1dbabab/highlights/059850Manual.mp4.....?
    
    try {
      let remoteType = this.getClipType(_clipDetails);
      if(remoteType){
        // console.log("Type:" ,remoteType);
        let remoteParts = remoteType.split("Remote");                  
        remoteType = remoteParts[1];
        if(remoteType.length<=1){return null;}
        remoteType = remoteType.toUpperCase();
        if(remoteType==='ZERO'){
          remoteType='RESET REF';
        }
        return remoteType;
      }
    } catch (error) {
      // console.log("Error on clip name extract: ",error,_clipDetails);
      return null; //on failure assume no match
    }
  }//end getTypeName
  /**
   * Parse the streamURL to check if the clips is a Remote type, if so they they outside border can be changed to indicate that the clip
   * was generated based on a remtoe command
   * @param {*} _clipDetails : the details returned from the API, includes the streamURL
   * @returns 
   */
  isRemoteCommandClip(_clipDetails){
      // Sample format of streamURL
      // https://d15n52xej5c0ab.cloudfront.net/devgroup/e3b_results/b9904c73-68f2-40a7-b812-f80ca1dbabab/highlights/059850Manual.mp4.....?
      
      try {
        //Parse the download url of the clip, need to discard everything but the filename to prevent accidental matches on other strings
        let clipName = this.getClipType(_clipDetails);
        if(clipName.includes('Remote')){ //check if the file name contains "remote"
          return true;
        }else{
          return false;
        }
      } catch (error) {
        // console.log("Error on clip name extract: ",error,_clipDetails);
        return false; //on failure assume no match
      }
  }//end isRemoteCommandClip
  

//Wrap this into a embedded function call to simplify the nested if statements
evaluateStyles(_inline){
  try {
      if(this.props.chosen !== this.props.name) {return {};} //is this set of clips visible
      if(!this.props.currentClip){return {};} //is a set of clips loaded into the player
      if(this.props.currentClip.count<=0){return {};} //are the clips in this set to evaluate
      if(this.props.currentClip.streamURL !== 'playlist'){return {};} //only process these for sets of clips, not skipping through a compiled video
      if(this.props.currentClip.chosenName ==='original' ){return {};} //don't process this for an original video type
      if(this.props.currentClip.chosenName ==='highlights' ){return {};} //don't process this for an highlights video type
  
      let style_next = JSON.parse(JSON.stringify(this.state.nextStyle));
      let style_prev = JSON.parse(JSON.stringify(this.state.prevStyle));
      //Reset the visibility to hidden, allow enable if the hidden check passes
      style_next.visibility='hidden';
      style_prev.visibility='hidden';
  
      //Check if there are clips available to the right of current position:
      if(this.isHidden(1)){  style_next.visibility="visible"; }
      //Check if there are clips available to the left of current position:
      if(this.isHidden(-1)){  style_prev.visibility="visible"; }

      // console.log("Hidden:" ,this.isHidden(-1), this.isHidden(1),this.props.loadCount,this.state.playlistOrder,style_prev)
  
      if(_inline){ //is this being called in the render loop?
        return {nextStyle:style_next,prevStyle:style_prev};
      }else{
        this.setState({nextStyle:style_next,prevStyle:style_prev });
      }
  } catch (error) {
    console.log("Fail on eval style:" ,error);
  }
}//end of evaluateStyles

  //Helper method to pull out the render logic for the control buttons from the main render
  // This is only for readability, it does not improve function or performance
  renderControls(){
    try {
      if(this.props.chosen===this.props.name){
        // console.log("Render controls triggers: ", this.props.chosen,this.props.name,this.props.currentClip.set[this.state.playlistOrder].timereviewed.getTime());
      }
      
      const {nextStyle={visibility:"hidden"},prevStyle={visibility:"hidden"}} = this.evaluateStyles(true);
      if(nextStyle.visibility==='hidden' && prevStyle.visibility==='hidden'){
        return (<div className="playbuttons"/>);
      }
      let clipReviewed = true;
      try {
        //Get the clip length
        let clipLengthMS = this.props.currentClip.set[this.state.playlistOrder].duration_ms || (8*1000); //use 8 seconds as a fallback for null data
        //Compute the required time before the skip button is allowed to be visible
        let requiredViewMS = clipLengthMS; //initialize to the clip length
        requiredViewMS *= 0.75; //require 75% of the time (6 seconds of an 8 second clip)
        requiredViewMS = Math.max(500, requiredViewMS); //set a minimum value of 500ms to prevent an underrun
        requiredViewMS = Math.min(clipLengthMS, requiredViewMS);//set the max value of clip length to prevent overrun
        let elapsedReviewTime = this.props.currentClip.set[this.state.playlistOrder].timereviewed.getTime();
        // console.log("requiredViewMS: ",requiredViewMS,clipLengthMS,this.props.currentClip.set[this.state.playlistOrder].infractionid);
        if(elapsedReviewTime < requiredViewMS && this.props.enableSkipRestrict){
        
          clipReviewed = false;
        }
        //Wait until the clip is loaded
        if(this.props.currentClip.set[this.state.playlistOrder].infractionid!== this.state.readyID){
          clipReviewed = false;
        }


      } catch (error) {
        // console.log("Failed to evaluate review time: ",error);
      }
      
      //Define the HTML objects to be rendered (use a input type to allow for the disabled property that is not available on img)
      return(  <div className="playbuttons">
                  <div className="blank-spacer"  />
                  <div className="blank-spacer"  />
                  <input type='image' src={IconArrowPrev} className="skipbutton" style={prevStyle} onClick={this.skipPrevClick}/> 
                  <input type='image' src={IconArrowNext} className="skipbutton" style={nextStyle} onClick={this.skipNextClick}
                                      disabled={!clipReviewed} /> 
                  <div className="blank-spacer"  />
                  <input type='image' src={IconRepeat} className="reorderbutton"  onClick={this.props.reorderClick} disabled={!clipReviewed}/> 
                   
                </div>
      );   
    } catch (error) {
      console.log("Fail on eval",error);
      return (<div/>)
    }
  }
}//end class definition
    
 
