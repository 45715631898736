
import React, {  PureComponent } from 'react';
import { setupPerf, perfDidClick  } from '../Perf.js';
import {getNoteCardFromInfractionID,allowedGroups } from '../Util.js';

 
import '../VideoReview/VideoReviewer.css';
import '../VideoReview/VideoClipPlayer.css';

import IconArrowPrev  from '../assets/arrowbox_prev.png';
import IconArrowNext  from '../assets/arrowbox_next.png';
import IconReject     from '../assets/reject-icon.png';
import { VideoClipPlayerBase,ENABLE_PLAYBACK_PROFILE } from './DVRVideoClipPlayerBase.js';
import {CanvasedVideo} from '../VideoReview/CanvasedVideo.js'
import { clipReviewAPI } from '../LiveTab/LiveTab-APIs.js';
/*
* @brief Handle the playback of a collection of clips, The notecards on the first tab displayed as a collection of clips
*  
* The parsing of the array indices, triggering the request to download, and playback state management are handled below
*/

export class VideoClipPlayerCollection extends VideoClipPlayerBase {
  constructor(props) {
      super(props);

      this.overlayButtonClick = this.overlayButtonClick.bind(this);
      this.onPlay = this.onPlay.bind(this);
      this.onEnded = this.onEnded.bind(this);
      this.onPause = this.onPause.bind(this);
      this.onReady = this.onReady.bind(this);
      this.onProgress = this.onProgress.bind(this);
      this.autoPlayVideo = this.autoPlayVideo.bind(this);
      this.skipNextClick = this.skipNextClick.bind(this);
      this.skipPrevClick = this.skipPrevClick.bind(this);
      this.ejectClick = this.ejectClick.bind(this);
      this.handleStop = this.handleStop.bind(this);

      this.loadClips = this.loadClips.bind(this);
      this.renderOverlayButton = this.renderOverlayButton.bind(this);

      this.isNotHidden = this.isNotHidden.bind(this);
      this.reportReview = this.reportReview.bind(this);
      this.isTimeElapsed = this.isTimeElapsed.bind(this);
      this.renderControls = this.renderControls.bind(this);
      this.evaluateStyles = this.evaluateStyles.bind(this);
      this.refreshInterval = this.refreshInterval.bind(this);

      this.state = {
          ...this.state, //load the state variables defined in the parent class
          loadCount: 0,
          loadedIDs:[],
          displayedInfraction:{},
          infractionTags:{},
          playlistOrder: 0,
          firstLoad: true,
          prevOrder: null,
          bookmarkLoad: this.props.bookmarkLoad,
          ejectmaskStream:[],
          ejectmaskID:[],
          ejectInfractionIDS:[],
          playtime:0,
          lastEjectedIDs: null,
          timeSinceRefresh: new Date(),
          allowClick:true,
          bLoadAllowed: this.props.bLoadAllowed,
          //Define the default style for each button in the control set
          prevStyle: {visibility:"hidden"}, //default state is hidden
          nextStyle: {visibility:"hidden"}, //default state is hidden
          ejectStyle: {visibility:"hidden"}, //default state is hidden
      };
      this.perfName = 'ExpandedCard';
      // Define a single references for the <video> used on infraction clips
      this.singlePlayerRef =  React.createRef(null);
      // this.bLog = this.props.currentClip.chosenName === 'Severe Drowsiness';
      this.bLog = this.props.currentClip.chosenName === 'Other - Unsafe';
      // this.bLog = this.props.currentClip.chosenName === 'Cellphone';
      // this.bLog = this.props.currentClip.chosenName === 'Manual';
  }
    
  /* @brief Called when new properties (input params) are passed to the class from the caller
  */
  UNSAFE_componentWillReceiveProps(newProps) {
    //Check if the new properties have a list of ejected infractionids that are due to action on other tabs:
    if(newProps.ejectedIDs !== this.state.lastEjectedIDs){
      const timeElapse = new Date() - this.state.timeSinceRefresh;
      if(timeElapse > 500){ //add a timer so this doesn't fire all the time
        // console.log("Got new ejectedIDS in the video player:", newProps.ejectedIDs)
        const ejectInfractionIDS = this.state.ejectInfractionIDS;
        //Check if these infractionids are ejected for this type of player (i.e. are they for the cellphone category)
        if(newProps.ejectedIDs){
          Object.keys(newProps.ejectedIDs).forEach(typeKey_ => {
            if(this.props.currentClip.chosenName === typeKey_){
              //Run through the ejected infractionids and check if we are already tracking this id, if not add it to the list
              (newProps.ejectedIDs[typeKey_].infractionIds || []).forEach(infractionID_ => {
                if(!ejectInfractionIDS.includes(infractionID_)){
                  ejectInfractionIDS.push(infractionID_);
                  // console.log("Adding: ",typeKey_,infractionID_,this.state);
                }
              })
            }//only do this if the types match
          });
        }
        

        //Send the updated state request:
        this.setState({lastEjectedIDs:newProps.ejectedIDs,timeSinceRefresh: new Date(),ejectInfractionIDS:ejectInfractionIDS},
          ()=>{//Check if the current clip needs to be skipped:
            if(ejectInfractionIDS.includes(this.getInfractionID(this.props.currentClip,this.state.playlistOrder))){
              if(this.isNotHidden(1)){this.skipNextClick();}
              else{this.skipPrevClick();}
            }
        });//end setState 
      }//end timer constraint
    }//end new props check


    // if(newProps.refreshTrigger !== this.props.refreshTrigger){
    //   console.log("Refresh trigger was updated? ",this.props.currentClip.chosenName,new Date() - this.state.timeSinceRefresh)
    if(newProps.bLoadAllowed !== this.props.bLoadAllowed || this.props.bLoadAllowed === true){ //the initial button will not have a change of status
      // if(this.bLog){console.log("Load status change? ",this.props.currentClip.chosenName,newProps.bLoadAllowed)}
      // {console.log("Load status change? ",this.props.currentClip.chosenName,newProps.bLoadAllowed, this.props.bLoadAllowed)}
      // console.log("Eval set to load: ",this.props.currentClip.chosenName, newProps.interruptTimer);     
      this.setState({bLoadAllowed:newProps.bLoadAllowed},()=>{
          //Pass the windowed set to the the load handler                    
          this.loadClips(this.props.currentClip.set);
      })
      
      //  let loadReturn = this.loadClips(setToPlay);
    }

    
    //Did we get a new data set to render?
    if(newProps.currentClip.set !== this.props.currentClip.set){
      // console.log("New clip props? ",newProps.currentClip.chosenName,newProps.currentClip.set);
      // if(this.bLog){console.log("New clip props? ",newProps.currentClip.chosenName,newProps.currentClip.set);}
      if(this.state.mPosition.curr){ 
        //  if(this.bLog){console.log("set position from state ",newProps.currentClip.set, JSON.parse(JSON.stringify(this.state.mPosition.curr)));}
        this.setCurrentPosition(newProps.currentClip.set,this.state.mPosition.curr,()=>{
          this.reportReview(this.state.mPosition.curr);
          this.autoPlayVideo();
        });
      }
      else{
        // if(this.bLog){console.log("Update position not from state? ");}
        this.setCurrentPosition(newProps.currentClip.set,newProps.currentClip.set[0],()=>{
          this.reportReview(this.state.mPosition.curr);
          this.autoPlayVideo();
        });
        // this.setCurrentPosition(newProps.currentClip.set,newProps.currentClip.set[0]);
      }
    }
      
    
    //  if(newProps.bLoadAllowed !== this.props.bLoadAllowed){
    // //   const timeElapse = new Date() - this.state.timeSinceRefresh;
    // //   if(timeElapse > 500){ //add a timer so this doesn't fire all the time
    //     console.log("Change allowed load:",this.props.currentClip.chosenName)
    //     this.setState({timeSinceRefresh:new Date()})
    // //   }
    //  }
  }//end UNSAFE_componentWillReceiveProps

  /* @brief Called when the components input parameters are updated
  */
  componentDidUpdate(newProps){
    // console.log("Did update called?")
          
    if(this.props.chosen !== newProps.chosen ){
      // console.log("Triggered chosen update", this.props.chosen,newProps.chosen)
      if(this.props.chosen !== this.props.currentClip.chosenName){ //check if the current asset is selected.          
        //Was this the previous chosen?
      }else{
        //asset selected: autoplay:
        // console.log("Chosen Updated: ",this.props.chosen);
        this.setState({mLoadingWindowSize:15},
        //force the playback to be called without using event listeners on the video          
        () => {  
            // console.log("Prop change, start video: ",this.props.currentClip.chosenName)
            this.autoPlayVideo(); 
          }
        );
        
      }
    }
  }
   
  /* @brief Called one time on load of the lcass, set the initial displayed infraction state.
  */
  componentDidMount() {
    // console.log("Player mounted: ",this.props)
    // if(this.bLog){console.log("Player mounted: ",this.props)}
    //  console.log("Player mounted: ",this.props.currentClip.chosenName, this.props.filename)

    window.addEventListener("optimizedResize", this.windowResized);
    
    this.setState({
      winSize : {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      }
    });

    //Set up the current clip and displayed infraction:
    this.setCurrentPosition(this.props.currentClip.set,this.props.currentClip.set[0],()=>{
      // console.log("Set current position: ",this.props.currentClip, this.state.mPosition)
    });      
    
    //Did we mount late?
    if(this.props.bLoadAllowed){
      //Start the preloading of the clips
      this.loadClips(this.props.currentClip.set);
    }
    
    }

    
  /* @brief Run once when the class is leaving
  */
  componentWillUnmount(){
    window.removeEventListener("optimizedResize", this.windowResized);
    if(this.refreshedTimer)(clearInterval(this.refreshedTimer));
    this._isMounted = false;
    this.handleStop();
  }

  /* @brief Actions to apply to the player when the component unmounts
  */
  handleStop(){
    // console.log("Stop: ",this.props.currentClip.chosenName);
    //the video player will be handled by the <CanvasedPlayer>    
  }

  /* @brief A helper function for the action that should be taken when the overlay button
  *        is clicked
  */
  overlayButtonClick(){
    //  console.log("Button click: ",this.state.displayedInfraction.InfractionID)
    let disableButton = false;
    (this.props.filter.role || []).forEach(role=>{
      if(role==='SiteManager'){disableButton=true;}
    })
    if(disableButton){return;}
    let details = {
      chosenName : this.props.currentClip.chosenName,
      Tag : this.state.displayedInfraction.Tag,
      infractionID : this.state.displayedInfraction.InfractionID,
      type: this.props.currentClip.type,
    }

    this.onPause();
    
    this.props.overlayButtonClick(details);
    
  } //end overlay button click

  /* @brief Callback handler when the video is playing
  */
  onProgress(_progress){
    // console.log("Video is playing: ",_progress, this.singlePlayerRef.current,this.singlePlayerRef.current.currentTime);
    // let currentTime = 10000;
    try {
      // currentTime = this.singlePlayerRef.current.currentTime;
      if(!_progress || !_progress.timeStamp){return;}    
      //Extract the seconds from the timestamp, 
      // trigger an update to the render/evalutation every two seconds
      let currentTime = (_progress.timeStamp/1000|0)%2;
      if(currentTime != this.lastUpdate && currentTime>0){
        this.refreshInterval();
        // console.log("OnProgress: ",_progress.timeStamp)
        this.evaluateStyles();
      }
      this.lastUpdate = currentTime;
    return;    
    } catch (error) {
      
    }
    
  
  }
  /* periodically trigger a re-eval of the hidden styles, to address cases of the no render updates (paused)*/
  refreshInterval(){
    if(this.refreshedTimer){clearInterval(this.refreshedTimer)}
    this.refreshedTimer = setInterval(()=>{
      // console.log("Interval triggered:" ,this.props.currentClip.set[this.state.playlistOrder].timereviewed.getTime())
      this.evaluateStyles();       
    }, 1000);
  }     
  /* Receive callback when the video playback ends*/
  onEnded(){
    if(this.props.chosen !== this.props.currentClip.chosenName){return}
      // console.log("Stopped playback?",this.props.currentClip.set[this.state.playlistOrder].timereviewed.getTime())
      // this.setState({refreshedTimer: new Date()});
      this.evaluateStyles();
      let data = {}
      try{
        let currentClip = this.state.mPosition.curr;
        data = {  infractionid: currentClip.InfractionID,
                  timerecorded: currentClip.timerecorded, //time of the clip (at the asset timezone)
                  chosenName: this.props.currentClip.chosenName,
                  clientid: this.props.groupconfig.group,
                  highlighted: this.props.chosen,                              
                }
      }catch(e){}
      //Send the update to the LiveTab
      if(this.props.onStop){this.props.onStop(data);}
    
  }
  /* Receive callback when the video is loaded and ready to play*/
  onReady(data){
    if(this.props.chosen !== this.props.currentClip.chosenName){return}
    //Debugging code: If EnablePlaybackProfile is enabled, output timers to the console logs
    try {
      if(ENABLE_PLAYBACK_PROFILE){
          window.performance.measure("StartPlayAfterSkip",this.props.currentClip.chosenName+'_PlaybackStart');
          // console.log(performance.getEntriesByType("measure"));
          console.log("Start playback: ",window.performance.getEntriesByName('StartPlayAfterSkip', 'measure')[0].duration);
          window.performance.clearMarks();
          window.performance.clearMeasures();
      }    
    } catch (error) {
      console.log("Failed to get time?",error);
    }
  }
  /* @brief Callback handler when the video starts to play  */
  onPlay(_data){
   if(this.props.chosen !== this.props.currentClip.chosenName){return}
   
    this.refreshInterval();
    let data = {}
    try{
      // console.log("Play: ",this.state.mPosition.curr);
      // console.log("Started playing the video?",this.props.currentClip.set[this.state.mPosition.curr],this.props.currentClip.set[this.state.mPosition.curr].timereviewed.getTime())
      let currentClip = this.state.mPosition.curr;
      data = {  infractionid: currentClip.InfractionID,
                timerecorded: currentClip.timerecorded, //time of the clip (at the asset timezone)
                chosenName: this.props.currentClip.chosenName,
                clientid: this.props.groupconfig.group,  
                highlighted: this.props.chosen,                              
              }
    }catch(e){}                    
    //Send the update to the LiveTab
    if(this.props.onPlay){this.props.onPlay(data);}
  }
  
  
  /* Receive callback when the video playback is paused*/
  onPause(){
    
    if(this.props.currentClip.streamURL === 'playlist'){      
      let currentVal = this.state.playlistOrder;
      // console.log("OnPause called: ", currentVal,this.state.prevOrder)
      if(!this.state.prevOrder){ //needs to handle the case when skipping backwards, without the video won't autoplay
      }else{
        this.setState({prevOrder:null}) ;
      }        
    }
    
  }
  /* Special call back to force the single player <video> tag to autoplay the video*/
  autoPlayVideo(){
    // return;
    if(this.props.currentClip.streamURL === 'playlist'){  //limit to only the infraction clips, not the highlight videos
      //  console.log("AutoPlay infraction?");
      if(this.props.chosen === this.props.currentClip.chosenName){ //not hidden      
        // console.log("PlayerRef: ",this.singlePlayerRef, this.singlePlayerRef.current);
        if(this.singlePlayerRef.current){
          try {
            // this.singlePlayerRef.current.load(); //Changed the source element, need to tell the player to reload the <source>
            this.singlePlayerRef.current.play();  
          } catch (error) {
            try {
              console.log("reload on fail to play?")
              this.singlePlayerRef.current.load(); //Changed the source element, need to tell the player to reload the <source>
              this.singlePlayerRef.current.play();  
            } catch (error2) {
              console.log("Auto play error 2: ",error2);  
            }
            console.log("Auto play error: ",error);
          }
        }
        this.reportReview(this.state.mPosition.curr); //Trigger a report to the API so that the review progress can be updated
      }else{}
    }
  }
  
   /* Helper method to return the current tag from the metadata*/
  reportReview(_currentTag){
    if(this._isMounted){      
      if(!this.isTimeElapsed(_currentTag)){return;} //don't report that the clip was reviewed until the min time has elapsed
      this.props.markViewed(this.props.currentClip.chosenName,_currentTag);
    }    
  }  
  
  /* @brief Handle the skip to next clip button press
  */
  skipNextClick(){
    //Need to transfer the next to the current positions
    // console.log("Skip next cliked: ",JSON.parse(JSON.stringify(this.state.mPosition)));
    if(ENABLE_PLAYBACK_PROFILE){ //Is profiling enabled, if yes then record that the skip button was pressed
      if(this.props.chosen === this.props.currentClip.chosenName){
        window.performance.mark(this.props.currentClip.chosenName+'_PlaybackStart');
      }
    }

    this.reportReview(this.state.mPosition.curr);

    try {      
      //Call update canvas to capture the current video clip, then pass the request to skip clips
        //Update the current position with the next position.
        this.setCurrentPosition(this.props.currentClip.set, //the set of clips that can be played
          this.state.mPosition.next,  //go to the next clip                             
          ()=>{setTimeout(this.autoPlayVideo,25)}  ); //start the video once the position is updated
    } catch (error) { console.log("Skip next error: ",error)  }
    
    if(this.props.chosen === this.props.currentClip.chosenName){
      let data = {}
      try{
        let currentClip = this.state.mPosition.curr;
        data = {  infractionid: currentClip.InfractionID,
                  chosenName: this.props.currentClip.chosenName,
                  clientid: this.props.groupconfig.group,  
                  highlighted: this.props.chosen,                              
                }
      }catch(e){}
      //Send the update to the LiveTab
      if(this.props.onSkip){this.props.onSkip(data);}
    }
  }
  /* @brief Handle the skip to previous clip button press
  */
  skipPrevClick(){
    // console.log("Skip prev cliked: ",JSON.parse(JSON.stringify(this.state.mPosition)));
    try{
      //Call update canvas to capture the current video clip, then pass the request to skip clips
        //Update the current position with the next position.
        this.setCurrentPosition(this.props.currentClip.set, 
          this.state.mPosition.prev,
          ()=>{setTimeout(this.autoPlayVideo,25)});
    } catch (error) {   }
    if(this.props.chosen === this.props.currentClip.chosenName){
      let data = {}
      try{
        let currentClip = this.state.mPosition.curr;
        data = {  infractionid: currentClip.InfractionID,
                  chosenName: this.props.currentClip.chosenName,
                  clientid: this.props.groupconfig.group,  
                  highlighted: this.props.chosen,                              
                }
      }catch(e){}
      //Send the update to the LiveTab
      if(this.props.onSkip){this.props.onSkip(data);}
    }
  }


  /* @brief Handle the what action to take when eject is pressed
  */
  ejectClick(){

      const ejectInfractionIDS = this.state.ejectInfractionIDS;
      // console.log("Eject Click: ",this.state.mPosition.curr);
      if(!this.props.ejectClick){return;}

        try{
          if(this.props.currentClip.type!=='playlist'){ return;}
          
          
            //Need to remove the infraction from the notecard, and or delete the note card if there aren't more infractions
            const infractionID = this.state.mPosition.curr.InfractionID;
            const card = getNoteCardFromInfractionID(this.props.notecards,infractionID);
            if(!card){ return;}
            if(!card.infractionTags){ return;}

            let tmpName = this.props.currentClip.chosenName;
            if(this.props.currentClip.flag && this.props.currentClip.flag == 2){
              tmpName += "-DF";
            }
            
            let ejectMaskID = this.state.ejectmaskID;
            ejectMaskID.push(this.state.playlistOrder);

            //add the current infractionID to the list of IDS that have been ejected.
            if(infractionID){ejectInfractionIDS.push(infractionID)}
            

            try {
              if(!card.video && this.props.currentClip.type ==='playlist'){
                card.video = this.props.currentClip.set[this.state.playlistOrder].streamURL;
              }  
            } catch (error) {   }

            let tagsToRetain = card.infractionTags.filter(tag_ => tag_!==this.props.currentClip.chosenName);
            // console.log("Eject from tags:" ,card.infractionTags,this.props.currentClip.chosenName,tagsToRetain);

            let toEjectObj = {
              cardID: card.cardID,
              
              infractionID:infractionID,
              infractionType: card.infractionType,
              streamURL: card.video,
              tag: card.tag,
              name: tmpName,
              type: 'playlist',
              infractionTags: card.infractionTags.filter(tag_ => tag_!==this.props.currentClip.chosenName),
              
            }
            //Is this from the second tab?
            if(this.props.currentClip.flag && this.props.currentClip.flag ===2){
              toEjectObj.notecardOnly= true;
              toEjectObj.delete= true;
              // toEjectObj.infractionTags.push(this.props.currentClip.chosenName);

              //Need to skip on this delete
              if(this.isNotHidden(1)){this.skipNextClick();}
              else{this.skipPrevClick();}
              if(this.props.ejectClick){this.props.ejectClick(toEjectObj);}

            }else{//Is the delete on the first tab?              
              toEjectObj.toRemove= this.props.currentClip.chosenName;
              //Trigger the update to capture the current video's frame, then send the request through to be processed
                // console.log("Canvas updated-> process eject")
              if(this.props.ejectClick){this.props.ejectClick(toEjectObj);}
              
            }
            return;
          
        } catch (error) { console.log("Error on eject click: ",error)   }
    } //end eject
   
    
  /* @brief helper method to find the infraction id in the current set of clips based on the index order
  */
  getInfractionID(_currentClip,_position){
    let infractionID = 0;
    try {
      infractionID = _currentClip.set[_position].InfractionID;
    } catch (error) {
    //  console.log("Failed to get infractionid: ",error); 
    }
    return infractionID;
  }

  /* Helper function to check the bound of the current position against the array length */
  isNotHidden(_direction){
    let index = this.getInfractionIDIndex(this.props.currentClip.set,this.state.mPosition.curr);
    if(index<0){return false;}
    if(_direction>0){
      let newVal =index+1;
      if(newVal < this.props.currentClip.set.length){
        return true;
      }
      return false;
    }else{
      let newVal = index -1;
      if(newVal < 0){return false;}
      return true;
    }
  }

  /* Loop through the window of clips and trigger a download */
  loadClips(_inputSet){

    //Filter the set of clips to load to reduce the download queue size:
    // setToPlay = this.props.currentClip.set.filter( (elem_,idx) => {
    let setToLoad = this.props.currentClip.set;
    if(this.props.bMemoryOptimized === false){
      //We are preloading, so set the window size:
      setToLoad = this.props.currentClip.set.filter( function(elem_,idx) {
          this.count++;
          if(this.interruptTimer){ //adding this timer check is pausing the background load?
            // console.log("Time since: ",new Date() - this.props.interruptTimer)
            if((new Date() - this.interruptTimer) < 1000)  {
                // console.log(" Return: ",idx,this.state.loadedIDs.length,idx <this.state.loadedIDs.length, (idx -this.state.loadedIDs.length) < 10)
              return  (idx -this.loadedIDs.length) < 4; //need to allow a buffer or else the background loading gets locked out                   
                
            }else{
          //     console.log("Out of interrupt?")                    
            }
          }
          // console.log("Filter: ",elem_,idx,this.state.loadedIDs.length)
          // console.log("FIlter array size:" ,this.count);
          return (idx -this.loadedIDs.length) < this.loadingWindowSize; //only allow window to load
        }, {count: 0, interruptTimer: this.props.interruptTimer, loadedIDs: this.state.loadedIDs, loadingWindowSize: this.state.mLoadingWindowSize}
      );//end filter definition
    }// end windowed set to load  


    // if(this.bLog){ console.log("enter loadClips: ",this.state.bLoadAllowed,this.props.currentClip.loadComplete)}
    if(this.state.bLoadAllowed===false){return 0;} //can we load, or are we waiting for the signal to start
    if(this.props.currentClip.loadComplete){return 0;} //set already finished loading

    const loadedIDs = this.state.loadedIDs
    let loadedCount = loadedIDs.length;

    //  if(this.bLog){ console.log("Loading clips for: ", this.props.currentClip.chosenName,loadedCount)}

    //Check if all have been loaded, if all are loaded, send the notificiation once
    // if(this.bLog){ console.log("is complete? ",loadedCount,this.props.currentClip.set.length, this.state.loadedIDs, _setToLoad)}
    if((loadedCount >= this.props.currentClip.set.length)){
      this.props.loadComplete({
        name: this.props.currentClip.chosenName,
        type: this.props.currentClip.type,
      })
    }

    setToLoad.forEach( (elem_,idx) => {
      // for (const [idx, elem_] of this.props.currentClip.set.entries()) {
      // this.props.currentClip.set.forEach( (elem_, idx)=> {
      if(this.props.bMemoryOptimized === false){ //Memory available -> direct download
        
        //Has the local file been created, or started?
        if(!elem_.blob){
          // console.log("Send Load callback? ",this.props.currentClip.chosenName,idx)
          //Trigger the download callback
          this.props.handleLoad({
            InfractionID: elem_.InfractionID,
            name: this.props.currentClip.chosenName,
            idx:idx,
          },true);
        }
        //If the blob is already loading then it will be set as pending
        if(!elem_.blob || elem_.blob === 'pending'){  return;}

      }else{ //need to limit memory-> use the stream URLs, don't create the local blobs of the clips
        //don't process these clips through the download function?
      }//end optimized loading loop

      //Check if this file is ready to play:
      let bLoaded = false;
      
      if(this.props.bMemoryOptimized){bLoaded = true;}
      if(elem_.blob && elem_.blob!=='pending'){bLoaded = true;}
      
      // if(this.bLog){ console.log("Add to loaded? ",idx,bLoaded,loadedIDs);}

      if(bLoaded && !loadedIDs.includes(elem_.InfractionID)){
        // console.log("add to loaded set: ",elem_.InfractionID);
        loadedIDs.push(elem_.InfractionID);
      }
    })//end loop

    //Recheck completion?
    loadedCount = loadedIDs.length;
    // if(this.bLog){ console.log("is complete, recheck? ",this.state.loadedIDs)}
    if((loadedCount >= this.props.currentClip.set.length)){
      this.props.loadComplete({
        name: this.props.currentClip.chosenName,
        type: this.props.currentClip.type,
      })
    }

    //Update the state
    this.setState({loadedIDs:loadedIDs});

    return 1;
  }//end loadClips

  /* Format the overlay render, take this out of the render loop to clean up the logic */
  renderOverlayButton(){
    //Don't handle the eject masks for the collection of clips

    let returnObj ={
      disableButton: false,
      reviewedButton: false,
      html:null,
    }
    //Don't display a overlay if the following conditions are met
    if(this.props.currentClip.chosenName === 'original'){   if(this.bLog){console.log("Original clip?");} return returnObj;}
    if(this.props.currentClip.chosenName === 'highlights'){  if(this.bLog){console.log("Highlight clip?");} return returnObj;}
    if(!this.state.mPosition.curr || !this.state.mPosition.curr.Tag){  if(this.bLog){console.log("OL no position", this.state.mPosition);} return returnObj;}

    //Create the overlay button, generate the txt and button objects:
    
    //Format the text to display in the button:
    let overlayText = <div><div>{this.state.mPosition.curr.Tag}</div></div>

    // console.log("Overlay render:" ,this.state.mPosition.curr,this.props, this.state.mPosition.curr.InfractionID )
    // console.log("Overlay render:" ,this.state.mPosition.curr,this.props.notecards,this.state.mPosition.curr.InfractionID,this.props);
    // console.log("Card found:" ,this.props.notecards && this.state.mPosition.curr.InfractionID && this.props.notecards[this.state.mPosition.curr.InfractionID] );

    //Update the button if in the 24hr review mode:
    if(this.state.mPosition.curr.reviewstatus  && this.state.mPosition.curr.reviewstatus==='reviewed'){
        returnObj.reviewedButton = true; //clip was reviewed
    }
    if(this.state.mPosition.curr.username){   

        overlayText = <div className='overlaybutton-text'>
                            <div className='overlay-number'>{this.state.mPosition.curr.Tag}</div>
                            <div className="overlay-reviewer">{this.state.mPosition.curr.username}</div>
                          </div>
    }//end 24hr review clip check

    //Check if the clip has a notecard:
    // if(this.bLog){console.log("Notecards to check: ",this.props.notecards)}
    if(this.props.notecards && this.state.mPosition.curr.InfractionID && this.props.notecards[this.state.mPosition.curr.InfractionID] ){
      returnObj.disableButton = false;  
      returnObj.html =  <button className="overlay-button" disabled={returnObj.disableButton} data-card="true" onClick={this.overlayButtonClick} > {overlayText} </button>;
    }else{ //no notecard is available:
      if(returnObj.reviewedButton){ 
        returnObj.html =  <button className="overlay-button" reviewed-clip="true" disabled={returnObj.disableButton} onClick={this.overlayButtonClick}> {overlayText} </button>; 
      }
      else{
        returnObj.html =  <button className="overlay-button" disabled={returnObj.disableButton} onClick={this.overlayButtonClick}> {overlayText} </button>;
      }

    }
    //Return to render to pass the HTML to the render stage
    return returnObj;

  }

  /** Helper method to break out the evaluation of the min time to view for the DVR clip */
  isTimeElapsed = (_currentPosition)=>{
    if(!this.props.enableSkipRestrict){return true;}
    if(!_currentPosition){return false;}
    
    try {
      // Get the clip length
      let clipLengthMS = _currentPosition.duration_ms || (8*1000); //use 8 seconds as a fallback for null data
      // Compute the required time before the skip button is allowed to be visible
      let requiredViewMS = clipLengthMS; //initialize to the clip length
      requiredViewMS *= 0.45; //require 45% of the time (3.6 seconds of an 8 second clip)
      requiredViewMS = Math.max(500, requiredViewMS); //set a minimum value of 500ms to prevent an underrun
      requiredViewMS = Math.min(clipLengthMS, requiredViewMS);//set the max value of clip length to prevent overrun
      let elapsedReviewTime = _currentPosition.timereviewed.getTime();
      // console.log("requiredViewMS: ",elapsedReviewTime,requiredViewMS,_currentPosition);
      if(elapsedReviewTime>requiredViewMS){return true;}
      return false;
    } catch (error) {
      return true;
    }
  }
  /** Render the video control buttons (skip, eject), update the style based on the prior review and elapsed review time */
  renderControls(){
    const isClipReviewed=(_currentPosition)=>{
      try {
        let clipReviewed =true;
        //Don't restrict a clip if it was already reviewed on a previous run:
        if(this.props.reviewedClips&& this.props.reviewedClips.viewed.has(_currentPosition.Tag)){return true; }
        
        //Return that it is not reviewed if the time restriction has not elapsed
        if(!this.isTimeElapsed(_currentPosition)){   clipReviewed = false; }
        //Wait until the clip is loaded
        if(this.props.currentClip.set[this.state.playlistOrder].infractionid!== this.state.readyID){ clipReviewed = false; }

        return clipReviewed
      } catch (error) {
        // console.log("Failed to evaluate review time: ",error);
        return true;
      }
    }
    try {
      if(this.props.chosen===this.props.name){
        // console.log("Render controls triggers: ", this.props.chosen,this.props.name,this.props.currentClip.set[this.state.playlistOrder].timereviewed.getTime());
      }
      
      const {nextStyle={visibility:"hidden"},prevStyle={visibility:"hidden"},ejectStyle={visibility:"hidden"} } = this.evaluateStyles(true);
      if(nextStyle.visibility==='hidden' && prevStyle.visibility==='hidden'){
        return (<div className="playbuttons"/>);
      }
      let clipReviewed = isClipReviewed(this.state.mPosition.curr);
      //Set up the control buttons to render 
      return (
        <div className="playbuttonsVR">
                        <div className="blank-spacer"  />
                        <input type='image' src={IconArrowPrev} className="skipPrev-button" style={prevStyle} onClick={this.skipPrevClick}/> 
                        <input type='image' src={IconReject} className="reject-button" style={ejectStyle} onClick={this.ejectClick} disabled={!clipReviewed} /> 
                        <input type='image' src={IconArrowNext} className="skipNext-button" style={nextStyle} onClick={this.skipNextClick} disabled={!clipReviewed} /> 
                        <div className="blank-spacer"  />
                      </div>);      
    } catch (error) {
      console.log("Fail on eval",error);
      return (<div/>)
    }
  }

   //Wrap this into a embedded function call to simplify the nested if statements
   evaluateStyles(_inline){
    try {
        if(this.props.chosen !== this.props.currentClip.chosenName) {return {};} //is this set of clips visible        
        if(!this.props.currentClip){return {};} //is a set of clips loaded into the player        
        if(this.props.currentClip.count<=0){return {};} //are the clips in this set to evaluate
        if(this.props.currentClip.streamURL !== 'playlist'){return {};} //only process these for sets of clips, not skipping through a compiled video
        if(this.props.currentClip.chosenName ==='original' ){return {};} //don't process this for an original video type
        if(this.props.currentClip.chosenName ==='highlights' ){return {};} //don't process this for an highlights video type

        
    
        let style_next = JSON.parse(JSON.stringify(this.state.nextStyle));
        let style_prev = JSON.parse(JSON.stringify(this.state.prevStyle));
        let style_eject = JSON.parse(JSON.stringify(this.state.ejectStyle));
        //Reset the visibility to hidden, allow enable if the hidden check passes
        style_next.visibility='hidden';
        style_prev.visibility='hidden';
        style_eject.visibility='hidden';
        
        //Check if there are clips available to the right of current position:
        if(this.isNotHidden(1)){  style_next.visibility="visible"; }
        //Check if there are clips available to the left of current position:
        if(this.isNotHidden(-1)){  style_prev.visibility="visible"; }

         //Only show the eject for the review group members:
         if(allowedGroups.includes(this.props.groupconfig.group)){
          style_eject.visibility="visible";  
         }//end group check

        if(_inline){ //is this being called in the render loop?
          return {nextStyle:style_next,prevStyle:style_prev,ejectStyle:style_eject};
        }else{
          this.setState({nextStyle:style_next,prevStyle:style_prev,ejectStyle:style_eject });
        }
    } catch (error) {
      console.log("Fail on eval style:" ,error);
    }
  }//end of evaluateStyles

  /*
  * @brief Render the content of the card:
  */
  render() {
    // console.log("Triggered player render", this.props.currentClip.chosenName,JSON.parse(JSON.stringify(this.state)))

    // let canvasToDraw = <canvas className={'canvas-dvr-player'} ref={this.canvasRef} />      
    if(this.state.bLoadAllowed === false){
        // console.log("Not load allowed");
        return (<div className={"video-reviewer-player" + " " + hiddenClass} ref = {this.vidPlayerClassRef}
                    key={"player-" + this.props.currentClip.chosenName}> </div>);
    }

    const hidden = this.props.chosen !== this.props.currentClip.chosenName;
    const hiddenClass = hidden ? "hidden-video" : "";

    // console.log("Data to render:" ,this.props)
    
    // choose if we should be hidden based on what the currently chosen video is
    let reviewText =null;
    let players = null;
    let URLToLoad= null;
    let hiddenTrigger = null;
    // console.log("Enter filter: ",new Date())
    // console.log("Props on clips: ",this.props);
    // console.log("Load? ",this.props.currentClip.chosenName,this.props.bLoadAllowed);            
    // if(this.bLog){console.log("Load completed? ",loadReturn, this.props.currentClip.loadComplete)}
    
    //Get the current element to play:
    let toPlay = this.state.mPosition.curr;
    if(!toPlay){ //position is false
        // console.log("current position not set: ",this.props.currentClip.chosenName);
       return (<div />); //don't try to play anything if we have no position data
    }

    //Check if this button is clicked
    if(hidden){ 

      if(this.props.currentClip.chosenName === 'cellphone'){
        console.log("Hiding cellphone:");
      }

      // console.log("Hidden");
      return (<div ref = {this.vidPlayerClassRef}/>);
    } //don't create the player if hidden

    try {
      // document.appendChild(document.getElementById('dvr-canvas'));  
    } catch (error) {
      
    }
    
    // console.log("After append? ",document.getElementById('dvr-canvas'))
    // console.log("Load current position: ",this.props.currentClip.chosenName,this.state.mPosition.curr);
    // console.log("Current clip: ",this.props.currentClip.set)
    
    if(this.getInfractionIDIndex(this.props.currentClip.set,toPlay)<0){
      // console.log("Cant find current clip in player: ",this.props.currentClip.chosenName);
      // console.log("Cant find current clip in player: ",
      //                                             JSON.parse(JSON.stringify(this.props.currentClip.set))
      //                                           ,JSON.parse(JSON.stringify(this.state.mPosition.curr))
      //                                           ,JSON.parse(JSON.stringify(toPlay)), toPlay.InfractionID
      //                                           ,this.getInfractionIDIndex(this.props.currentClip.set,toPlay)
      //                                         );
      //Clip was deleted? try to update the position:
      // this.setCurrentPosition(this.props.currentClip.set,this.state.mPosition.curr);
      this.setCurrentPosition(this.props.currentClip.set,this.state.mPosition.curr,this.autoPlayVideo);
      URLToLoad = null;        
    }else{
      //Set the URL to play as the local blob, if it exists, if not then load the stream URL from AWS
      if(toPlay.blob){
        URLToLoad = toPlay.blob;
      }else if(this.props.bMemoryOptimized){
        URLToLoad = toPlay.streamURL;
      }                        
    }

   

    //If the Video isn't available then just return an empty div
    if(!URLToLoad||URLToLoad==='pending'){      
      // console.log("Missing URL?");
      return  (<div></div>);
    }

    // console.log("Check current URL: ",this.props.currentClip.chosenName,toPlay, URLToLoad);
    if(hiddenTrigger !== 'hidden-video'){ hiddenTrigger = 'react-player' }
    
    //Debug the load order
    // if(this.props.chosen === this.props.currentClip.chosenName && URLToLoad){
    //    console.log("Playing video: ",this.props.name,this.state.playlistOrder,URLToLoad)
    //   console.log("Load order: ",this.props.name, this.props.currentClip)
    // }

     //Define the player to show the video
    players=  <CanvasedVideo className='fixed-size'
                  ref = {this.singlePlayerRef}
                  preload="auto"
                  controls
                  onCanPlay={this.onReady}
                  onPlay={this.onPlay}
                  onEnded={this.onEnded}
                  onPause={this.onEnded}
                  onProgress={this.onProgress}
                  src= {URLToLoad}
                />

    //Render the overlay button
    let overlayObj = this.renderOverlayButton();
    // if(this.bLog){console.log("Overlay: ",overlayObj)}

    //Format the classname for the top level div
    let className = "video-reviewer-player "+this.props.currentClip.chosenName+" " + " " + hiddenClass
    //Format the returned video playback to render
    const res =(
      <div className={className}  key={"player-" + this.props.currentClip.chosenName} ref = {this.vidPlayerClassRef}>
          {players}
          {reviewText}
          {overlayObj.html}
          {this.renderControls()}
          {/* <div>{this.props.refreshTrigger.format('YYYY-MM-DD HH:mm:ss')}</div> */}
          
      </div>
    );
    // console.log("Full render",JSON.parse(JSON.stringify(this.state)));
    return res;
  }
  
}//end class definition
