
import React, {  PureComponent } from 'react';
import {VideoClipPlayerCollection} from './DVRVideoClipPlayerCollection.js'
import {VideoClipPlayerStream} from './DVRVideoClipPlayerStream.js'

import '../VideoReview/VideoReviewer.css';
import '../VideoReview/VideoClipPlayer.css';

/*
* @brief Functional component to wrap the class call
*/
const VideoClipPlayer = ({  currentClip,groupconfig, filter,notecards,bookmarkLoad,chosen,ejectSets,ejectedIDs,ejectSetName
                            ,username,filename,playerKey,bMemoryOptimized,handleLoad,loadComplete
                            ,markViewed, overlayButtonClick, roiSelectClicked, ejectClick, undoEjectClick, currentlyPlaying,bLoadAllowed
                            ,refreshTrigger,ref,type,interruptTimer,...rest }) => {
//  console.log("Received VCP: ",currentClip);

  let player = null;
  {(currentClip.type && currentClip.type==='playlist')?

    player = <VideoClipPlayerCollection
              ref = {ref}   //return a reference of the class to the parent class              
              currentClip={currentClip} //pass the details of the currently selected clip type. This is includes the set of clips to play
              chosen={chosen} //Pass the name of the currently selected button
              filter={filter} //Pass the currently selected filter parameters
              groupconfig= {groupconfig} //Pass the details of the group configuration, this includes the groupname, allowed sites, roles, etc.
              notecards={notecards} //Pass the list of notecards
              bookmarkLoad = {bookmarkLoad} //Pass the loaded progress from the previous review
              ejectSets={ejectSets} //Pass the set of ejected clips 
              ejectedIDs = {ejectedIDs} //Pass the set of ejected clips as infractionIDs
              ejectSetName = {ejectSetName} //Pass the set name of the ejected clips, 
              username = {username} //Pass the username of the signed in user: example: dev_user
              filename = {filename} //Pass the Filename that is displayed as the tab title
              playerKey={playerKey} //Pass a key to the video player, can be used to force a manual reload

              markViewed={markViewed} //Callback method to indicate a clip has been reviewed - used to update the progressbar
              overlayButtonClick = {overlayButtonClick} //Callback method when the overlay button is cliked
              roiSelectClicked = {roiSelectClicked} //Callback method when the ROI selection button is clicked                              

              ejectClick = {ejectClick} //Callback method when the eject button is clicked
              undoEjectClick = {undoEjectClick} //Callback method when the undo eject button is clicked

              currentlyPlaying = {currentlyPlaying}  //Callback method to pass the details of the currently playing clip
              bLoadAllowed = {bLoadAllowed} //Property to indicate that the class may download its clips
              loadComplete = {loadComplete} //Callback method to indicate when the class has completed loading all clips
              bMemoryOptimized = {bMemoryOptimized}  //Property to enable/disable loading URLs into local blob files    
              handleLoad = {handleLoad} //Callback method to handle when a call to load/download a file is made

              refreshTrigger = {refreshTrigger} //Property to force a manual refresh
              type ={type} //Property listing the type of asset 
              interruptTimer = {interruptTimer} //Property indicating that a notecard has been opened, and should be prioritized in the download queue
              {...rest}
            />
  :
    player = <VideoClipPlayerStream
              ref = {ref}               
              currentClip={currentClip}                  
              chosen={chosen}                
              filter={filter}
              groupconfig= {groupconfig}
              cardsByType={notecards}
              bookmarkLoad = {bookmarkLoad}
              ejectSets={ejectSets}
              ejectedIDs = {ejectedIDs}
              ejectSetName = {ejectSetName}
              username = {username}  
              filename = {filename}
              playerKey={playerKey}

              markViewed={markViewed} 
              overlayButtonClick = {overlayButtonClick}
              roiSelectClicked = {roiSelectClicked}                              

              ejectClick = {ejectClick}
              undoEjectClick = {undoEjectClick}

              currentlyPlaying = {currentlyPlaying}  
              bLoadAllowed = {bLoadAllowed}
              loadComplete = {loadComplete}
              bMemoryOptimized = {bMemoryOptimized}      
              handleLoad = {handleLoad} 

              refreshTrigger = {refreshTrigger}
              type ={type}
              interruptTimer = {interruptTimer}
            />
  }
  return player


};
export { VideoClipPlayer };
    
 