import React from 'react';
// import './VideoShaka.css'
import {getNodePosition} from '../Util.js'

/**
 * Wrapper for the <video> element to manager transitions in source file
 * @param {string} src
 */
export const CanvasedVideo = React.forwardRef(({ src, className,note, onCanPlay, ...rest },ref) => {
  //Video management
  const videoRef = React.useRef(null); // reference to video, doesn't trigger a render
  const sourceRef = React.useRef(null); // reference to source element, doesn't trigger a render (<source> tag for Safari)
  const [loadTime, updateTimer] = React.useState(new Date()); //Set a timer when the component first mounts

  //Canvas Managememt
  const canvasRef = React.useRef(null); //a reference to the local canvas 
  const canvasTimeoutRef = React.useRef(); //we don't want to trigger a render
  const [ canvasSet,updateCanvasState] = React.useState(null); //Indicate that the canvas is set, size and position are known
  const [ canvasStyle, setCanvasStyle] = React.useState({}); //Canvas style to pass to the component, changes the display from block to none
  const [ canvasReady, setCanvasDrawn] = React.useState(null); //Canvas is ready, a frame has been written to the canvas
  

  // Effect to handle component mount & unmount.
  // This should always be the first effect to run.
  React.useEffect(() => {
    //Listen for changes that will impact the location/size of the canvas overlay
    window.addEventListener("scroll", onWindowChange); //listen for scrolling
    window.addEventListener("optimizedResize", onWindowChange); //listen for changes to the size of window

    

    return () => {
      window.removeEventListener("scroll", onWindowChange); //release the listeners(leak memory if not released)
      window.removeEventListener("optimizedResize", onWindowChange); //release the listeners(leak memory if not released)
     
      if(canvasTimeoutRef && canvasTimeoutRef.current){clearTimeout(canvasTimeoutRef.current)} //release the timeouts(leak memory if not released)

      //Release the src video, leaks memory otherwise
      try {
        videoRef.current.src = null;
        sourceRef.current.src = null;
        videoRef.current.load();
      } catch (error) {
        // console.log("failed to release on Shaka ",videoRef);
      }
    };
  }, []);
  /*Callback when the window size or position (scroll) changes - need to signal to the Canvas to update the size/position */
  function onWindowChange() {  updateCanvasState(null); }

  /*Get the canvas from either the local reference or the DOM element*/
  function getCanvas(_callerID){
    // console.log("GetCanvas called by: ",_callerID)
    let canvas = null;
    if(canvasRef){canvas = canvasRef.current;} //Has this be defined as a local component
    if(!canvas ){  //is the reference unknown
      //Not found, load from name?
      canvas = document.getElementById('onVideoCanvas');//Find the canvas by the id:
      // console.log("Loaded canvas? ",canvas);
    }
    // console.log("Return Canvas: ",canvas);
    return canvas;
  }
  /*Update the size and position of the canvas */
  function resizeCanvas(_canvas, _position,_width,_height){
    //  console.log("==Set size:", _position,_width,_height);
    
    const canvas = _canvas||getCanvas("resize");
    if(!canvas){console.log("Resize canvas exit 1");return -2;}

    if(canvasSet){return 0;} //Don't set the size of the canvas if it is already set

    let newStyle = Object.assign({}, canvasStyle);
    // console.log("Resize the canvas - clears the content");
    //Define the size of the canvas, this should match the size and position of the video player
    canvas.setAttribute("width", _width + "px"); //changing the width will clear the current canvas content
    canvas.setAttribute("height", _height-1 + "px");

    newStyle.width = _width + "px";
    newStyle.height = _height + "px"

    let left = _position.left||0;
    let top = _position.top||0;
    //offset from video for debugging
    //working at 0.25?
    // left += 0.25;
    //  left += 100;
    // top += -5;
    
    //Update the X,Y position to the top left of the video player:
    // canvas.style.left = (left) +'px';
    // canvas.style.top = (top) +'px';

    newStyle.left = left + "px"
    newStyle.top = top + "px"

    // console.log("Resize with: ",newStyle);
    if(_width > 0 && _height > 0){
      setCanvasStyle(newStyle);
    
      updateCanvasState(true);
    }


  }

  /* Update the canvas with the last frame of the video*/
  function updateCanvas(_playerRef, _doAfter){
    //  console.log("Update called: ",_playerRef);
    // if(ENABLE_PLAYBACK_PROFILE){  window.performance.mark('UpdateCanvas');}

    let canvas = getCanvas("update");    
    if(!canvas){console.log("Early exit canvas 2");return -1;}
    if(!_playerRef || !_playerRef.current){ console.log("Early exit canvas 2"); return -1;}
    

    //Update the canvas to match the size of the video:
    let width = _playerRef.current.offsetWidth;
    let height = _playerRef.current.offsetHeight;
    let pos = getNodePosition(_playerRef.current);
    resizeCanvas( canvas,pos,width,height); 
    
    
    //Draw the frame
    if(_playerRef.current.readyState >= 2){ //only capture the frame if the video is in a ready state
      // console.log("Ready state pass");
      const ctx = canvas.getContext('2d');
      ctx.drawImage(_playerRef.current,0,0,canvas.width,canvas.height); //pass the video object to fill the full canvas
      // console.log("Set canvas drawn true");
      setCanvasDrawn(true);
    }else{
      // console.log("=== ERROR NO DATA? ===",_playerRef.current.readyState ); //otherwise just re-use the old frame? still mask the flicker?
    }
    // if(ENABLE_PLAYBACK_PROFILE){  window.performance.measure("UpdateCanvas",'UpdateCanvas'); }
  }//end updateCanvas

  /* Switch the canvas display property so that it is visible*/
  function showCanvas(){
    let canvas = getCanvas("hide");    //Get the canvas
    if(!canvas){
      // console.log("Hide canvas exit 1");
      return -1;
    }
    //Add safety timeout
    if(canvasTimeoutRef && canvasTimeoutRef.current){clearTimeout(canvasTimeoutRef.current)} //clear the previous timeout
    // console.log("Set the timeout")
    canvasTimeoutRef.current = setTimeout(()=>{hideCanvas('timeout')},500);

    //Change the state to block to enable the canvas to show:
    if(canvas.style.display === "none"){
      let newStyle = Object.assign({}, canvasStyle);
      newStyle.display = 'block';
      // console.log("Show with: ",newStyle);
      setCanvasStyle(newStyle);
    }
  }//end of showCanvas

  /* Change the display attribute of the canvas to hide (no longer obscure the video player area)*/
  function hideCanvas(_source,_doAfter){
    //  console.log("Triggered from ", _source); //debug how the canvas hide was triggered
    if(!canvasReady){  
      // console.log("Canvas not ready?");
      return;
    }
    let canvas = getCanvas("hide");    //Get the canvas
    // console.log("Hiding? : ",canvas,note);
    if(!canvas){console.log("Hide canvas exit 1",canvas,note);return -1;}
    if(canvas.style.display !== "none"){
      // canvas.style.display = "none";
      let newStyle = Object.assign({}, canvasStyle);
      newStyle.display = 'none';
      // console.log("Hide with: ",newStyle, canvasReady);
      setCanvasStyle(newStyle);
      setCanvasDrawn(null); //set the state that we want new data in the canvas before rendering again
      if(canvasTimeoutRef && canvasTimeoutRef.current){clearTimeout(canvasTimeoutRef.current)}      
    }
  }//end hideCanvas

  // Define a handle for easily referencing Shaka's player & ui API's.
  React.useImperativeHandle(
    ref,
    () => { 
      return videoRef.current;
    },[videoRef]);


  //Monitor that the video player has loaded, and that the class name
  //When the classname changes it is switching from hidden to the fixed-player
  React.useEffect( ()=>{
    updateTimer(new Date());
    try {
      // console.log("The ref was set: ",className)
      
      let cWidth = videoRef.current.offsetWidth;
      //check if the element is loaded yet
      if(cWidth <=0){return;} //dont continue with computation if not loaded 
  
      //Get the remaining dimensions of the parent and player elements
      let pWidth = videoRef.current.parentNode.offsetWidth;
      let cHeight = videoRef.current.offsetHeight;
      let pHeight = videoRef.current.parentNode.offsetHeight;
      //Compute a scale, we want to rescale the video to fit into the parent container
      //This is would be handled automatically by the CSS property object-fit: fill
      //We would use that if Safari could follow standards
      let scaleX = pWidth/cWidth;
      let scaleY = pHeight/cHeight;

      // console.log("REscale? ",scaleX,cWidth,pWidth);
  
      //Set custom CSS attributes so that our style can alter the element:
      videoRef.current.style.setProperty('--vidScaleX',scaleX); //scale horizontally
      videoRef.current.style.setProperty('--vidScaleY',scaleY); //scale vertically
      
      // console.log("Shaka from Ref: ",videoRef,pWidth,cWidth,scaleX,scaleY);
    } catch (error) {
      console.log("failed to derive video size from parent");      
    }
    
    //Disable right clicking
    // const handleContextmenu = e => {  e.preventDefault()  }
    // videoRef.current.addEventListener('contextmenu', handleContextmenu)
    // return ()=>{
    //   videoRef.current.removeEventListener('contextmenu', handleContextmenu)
    // }
    

  },[videoRef,className]) //we need to have the videoRef that we want to rescale, and know when the class is not hidden

  /* Callback returned when the video has loaded the src and is ready to start playing*/
  function handleCanPlay(_data){
    // console.log("Can play returned ",videoRef.current.readyState, new Date() - loadTime);    
    updateCanvas(videoRef);
    onCanPlay();
  }

  // Load the source url when we have one.
  React.useEffect(() => {
    
    updateTimer(new Date());
    // console.log("Src change requested");

    setCanvasDrawn(null); //set a dirty flag to indicate that the canvas needs to update since we got a new clip
    showCanvas();
      // console.log("Canvas state: ",canvasRef.current.style.display)
    
    setTimeout(()=>{
      if(videoRef && videoRef.current){
        sourceRef.current.src = src; //reference the <source> component so we can keep the type set
        videoRef.current.load();
      }
    },25); //Timeout is necessary for Safari to read the new data

  }, [videoRef, src]); //listen that the src has changed


  /*Called as new frames are rendered by the video */
  function onProgress(_data){
    try {
      let currentTime = videoRef.current.currentTime;
      // console.log("Play progres: ",_data.timeStamp, currentTime)  
      if(currentTime > 0.1){
        hideCanvas();
      }
      if(currentTime > 1){
        updateCanvas(videoRef);
      }
    } catch (error) {}
    if(rest.onProgress){rest.onProgress(_data)}
  } //end onProgress callback

  /* Rendered block, detemines what is returned*/
  return (
    <React.Fragment>
      <video
        className={className}
        muted="muted"
        autoPlay //should this be enabled, or rely on the trigger in the callback ref?
        controls         
        ref={videoRef}
        onLoad={(_data)=>{console.log("Load returned ",videoRef.current.readyState, new Date() - loadTime)}}
        onCanPlay={handleCanPlay} //has loaded enough data to start playing the clicp
        // onCanPlayThrough={handleCanPlay} //has loaded enough data to play the entire clip
        onTimeUpdate={onProgress} //playing the clip, triggers callback on new frames
        {...rest} //load up any other parameters passed by the parent
        controlsList="nodownload" //block the in player download request
      >
        <source ref= {sourceRef} type="video/mp4"></source>
      </video>
      {/* Define the local canvas to be used as the mask for the video */}
      <canvas style={canvasStyle} className = 'onVideoCanvas' id = {'onVideoCanvas-'+note} key = {'onVideoCanvas-'+note}  ref={canvasRef} />
      
    </React.Fragment>  
  );
});

