
import React, { Component } from 'react';

import '../VideoReview/VideoHighlight.css';

import { Auth, API } from 'aws-amplify';

import { delayPromise, VIDEO_STATUS, getStreamURL,filenameAlphaStripper } from '../Util.js';
import { VIDEOREVIEW_BYPASSARCHIVE } from '../Util-access.js';
import { setupPerf } from '../Perf.js';


import { VideoReviewer } from './DVRVideoReviewer.js';

import axios from 'axios';

import { ArchiveDialog,ArchivedRetrieval } from '../ArchiveDialog';



import * as moment from 'moment';

import 'react-virtualized/styles.css'

// Bring in the React libraries for the bootstrap table
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

// Debug helpers for testing the API call logic
const DEBUG_DELAY_API_RESPONSE = false;
const DEBUG_DELAY = 10; // in seconds



/*
* @brief Display a page of video highlights for a particular source video
*/
class VideoHighlight extends Component {
  constructor(props) {
    super(props);
    this.processUpdateData = this.processUpdateData.bind(this);
    this.updateData = this.updateData.bind(this);
    this.publishHighlights = this.publishHighlights.bind(this);
    this.rejectHighlights = this.rejectHighlights.bind(this);
    this.getApiCall = this.getApiCall.bind(this);
    this.onLoadingState = this.onLoadingState.bind(this);
    // this.refreshCallback = this.refreshCallback.bind(this);
    // this.updateDataRefresh = this.updateDataRefresh.bind(this);
    this.tagChange = this.tagChange.bind(this);
    this.cardChange = this.cardChange.bind(this);
    this.updateVideos = this.updateVideos.bind(this);
    // this.child = React.createRef();
    this.onArchiveDialogClose = this.onArchiveDialogClose.bind(this);
    this.state = {
      vid_info: null,
      data_set: false,
      loadTime: false,
      should_show: false,
      video_card: null,
      pathArray: null,
      username: null,
      reviewername: null,
      archived: 0,
      timeavailable: null,      
      review24Hr: this.props.flag24Hr,
    };

    // if (window.performance) {
    //   if (performance.navigation.type == 1) {
    //     alert( "This page is reloaded" );
    //   } else {
    //     alert( "This page is not reloaded");
    //   }
    // }

    setupPerf(this, 'VideoHighlight', () => this.state.data_set);
  }
  onLoadingState(state) {
    this.setState({loadingState: state});
  }
  /* @brief Run once when the class is loaded
  */
  componentDidMount() {
    let refreshPromise = this.getApiCall();
    refreshPromise.then(data => {
      this.updateData(data);
    });
  }//end didmount
  /* @brief Run once when the class is leaving
    */
  componentWillUnmount(){
    // URL.revokeObjectURL()
    const videos = this.state.videos;
    const cards = this.state.cardsByType;
    try{
      //Try to release all blob objects created for the video playback:    
      if(videos && videos.original && videos.original.streamURL){URL.revokeObjectURL(videos.original.streamURL)}
      if(videos && videos.highlights && videos.highlights.streamURL){URL.revokeObjectURL(videos.highlights.streamURL)}
      Object.values(( videos.clips || {})).forEach(typeArray => {
        typeArray.forEach(clip => {
          if(clip.streamURL){URL.revokeObjectURL(clip.streamURL)}
        });
      }); 
      Object.values(( videos.byType || {})).forEach(type => {
        if(type.set ){
          (type.set || []).forEach(elem=>{
            if(elem.streamURL){URL.revokeObjectURL(elem.streamURL)}
          })
        }else{
          if(type.streamURL){URL.revokeObjectURL(type.streamURL)}
        }
      }); 
      videos.original = null;
      videos.highlights = null;
      videos.clips = null;
      videos.byType = null;
      videos.byID = null;
      videos.byDriver = null;

      //Release any url blobs on the cards:
      Object.keys(cards).forEach(_key => {
        Object.values(cards[_key]).forEach(_card => {
          if(_card && _card.photo){_card.photo =null;}
          if(_card && _card.video){URL.revokeObjectURL(_card.video)}
        });
      });

      this.setState({videos:videos, cardsByType: cards});
      this.setState({videos:null, cardsByType:null});
    }catch(e){

    }
   
  }
  
  /*
  * @brief The definition of the API call that we need to do to display this list
  */
  getApiCall() {
     console.log("Calling API to retrieve: ",this.props.video.uniqueKey);
    // console.log("Calling API to retrieve: ",this.props);

    // let flag24Hr= false;

    // let allowedGroupList = ['DEVGROUP','TESTINGGROUP','REVIEWGROUP','BETAGROUP'];
    // if(allowedGroupList.includes(this.props.groupconfig.group.toUpperCase())){
    //   if(this.props.video.vehicleType==='24Review'){
    //     flag24Hr = true;
    //   }
    // }
    // this.setState({review24Hr:flag24Hr});

    // Create the API call + promise wrapper that will get the video highlights
    const highlightsPromise = Auth.currentSession().then(
      (auth) => {
        let apiName = "AuthLambda";
        let path = "/getVideoHighlights";
        let myInit = {
          body: {
            token: auth.idToken.jwtToken,
            video: this.props.video.uniqueKey,
            filter: this.props.filter,
            dashed: true,
            vehicletype: this.props.video.vehicleType,
            flag24Hr: this.state.review24Hr,        
            checkarchived: VIDEOREVIEW_BYPASSARCHIVE?false:true,    
          }
        };
        this.setState({username:auth.idToken.payload['cognito:username']})
        return API.post(apiName, path, myInit);
      });
    const parentID = this.props.video.simulated ?
                        this.props.video.uniqueKey + "-" + this.props.video.filename :
                        this.props.video.uniqueKey;
    // Create the API call + promise wrapper that will get the saved Video Cards
    const cardsPromise = Auth.currentSession().then(
      (auth) => {
        let apiName = "AuthLambda";
        let path = "/getCardsForReview";
        let myInit = {
          body: {
            token: auth.idToken.jwtToken,
            video: parentID,
            vehicletype: this.props.video.vehicleType,
          }
        };
        return API.post(apiName, path, myInit);
      });

      const reviewPromise = Auth.currentSession().then(
        (auth) => {
          let apiName = "AuthLambda";
          let path = "/getReviewProgress";
          let myInit = {
            body: {
              token: auth.idToken.jwtToken,
              video: this.props.video.uniqueKey,
            }
          };
          return API.post(apiName, path, myInit);
        });


          

    // Create the combined promise from both of the above API calls
    // const combinedPromise = Promise.all([highlightsPromise, cardsPromise, driverPromise,journeyPromise, listPromise,reviewPromise]);
    const combinedPromise = Promise.all([highlightsPromise, cardsPromise,reviewPromise]);
    if (DEBUG_DELAY_API_RESPONSE) {
      return delayPromise(combinedPromise, DEBUG_DELAY * 1000);
    } else {
      return combinedPromise;
    }
  }

   /*
  * @brief Process the data wether from file or REST json
  */
  processUpdateData(_data, _cards,_cardsByType,_reviewPromise){
    var unpublished = 0;
    const byType = {};

    var original_video = null;
    var originalDeleted = false;
    var highlights_video = null;
    var ejected_video = null;

    const clips = {};
    const videosByID = {};
    const driverByID = {};
    const videosFlags = {};
    const byInfractionID = {};
    const reviewerByInfractionID = {};

    let bAllowReviewVideo = false;
    let disabledCount =0;

    // console.log("Data: ",_data);
    _data.forEach(row => {
      //  if(row.journeytime){console.log(row);}
      // Add the 'count' from the video for each combined infraction video
      if (row.type === 'Combined') {
        // console.log("Combined: ",row);
        row.count = this.props.video[row.classification + 'Count'] || 0;
      }
      const type = row.type.toLowerCase();

      let bAllow = true;
      if(this.props.groupconfig.disabledInfractionSet && this.props.groupconfig.disabledInfractionSet.includes(row.classification)){
        
        if(row.count){disabledCount += parseInt(row.count);}
        // console.log("disabled row: ",row.count,disabledCount);
        bAllow = false;
      }
      
      const classification = row.classification.toLowerCase();
      //update the stream to handle URL or Blob type
      //  if(row.streamURL && classification ==='source'){
      //    console.log("InStream: ",classification,row.streamURL);
      //  }
      row.streamURL =  getStreamURL(row.streamURL);
      //  if (type === 'original') {
      //     console.log("OutStream: ",row.streamURL);
      //  }

        
      

      //Don't add the infraction video to the display list if it was in the disabled infraction set:
      if(bAllow){ 
        if (type === 'original') {
          original_video = row;
          //was this video marked as deleted?
          if(row.s3key==='deleted'){originalDeleted = true;}
        } else if (type ==='combined') {
          // console.log("Type: ",classification);
          if (classification === 'common') {
            highlights_video = row;
          } 
          else if(classification === 'ejected'){
            ejected_video = row;
            byType[classification] = row;
          }
          else {
            byType[classification] = row;
          }
          //Check if this is a live review video, this will relax requirment for highlights or original videos
          if(row.s3key.toLowerCase().includes('review_dash')){
            bAllowReviewVideo = true;
          }
          
        } else if (type === 'infraction') {
            clips[row.classification] = clips[row.classification] || [];        
            clips[row.classification].push(row);
        }
        else if (type === 'trip') {
          // byType[classification] = row;
          clips[row.classification] = clips[row.classification] || [];        
          clips[row.classification].push(row);
        }
        unpublished+= row.flag;
        // The metadata is is JSON format, but we want actual JS types (an array),
        // so parse it
        try {
          row.metadata = row.metadata && JSON.parse(row.metadata);  
        } catch (error) {
          console.log("Parse fail: ",row.metadata,error)
        }
        

        if (row.infractionID) {
            byInfractionID[row.infractionID] = row;            
            videosByID[row.infractionID] = row.streamURL;            
            driverByID[row.infractionID] = row.driverid;
            videosFlags[row.infractionID] = row.flag;
            reviewerByInfractionID[row.infractionID] = {status:row.reviewstatus, username:row.username};
        }
      }
      
    });
    //  console.log("ByType: ",byType);
    //  return;
    //  console.log("ByID: ",videosByID);
      // console.log("InfId: ",byInfractionID);
    //-------------------------------------------------
    //Add links to each tagged infraction:
    //-------------------------------------------------
    try {
      //Add space holder for each type of infraction tag:
      let iLoadIdx=1;
      (this.props.groupconfig.infractionTags || []).forEach(_tag=>{
        //  console.log(_tag);
        if(_tag.type === 'Irrelevant'){ return;}
        if(!byType[_tag.type]){
        
          byType[_tag.type]={ //define a generic detail to hold the cards?
          // streams: [], 
          set: [],
          classification: _tag.type,            
          count: 0,
          type: 'playlist',streamURL: 'playlist',
          loadOrder: iLoadIdx++,
          };   
        }
        
      });

      let duplicatedTypeName = ['Ingress','Egress','OtherHighG'];
            
      // console.log("Cards:" ,_cards);
      _cards.forEach(card => {
        if(card.infractionTags){
          //  return;
          // console.log("Look at card: ",card,byInfractionID[card.infractionID]);
          //Iterate over all tags:
          (card.infractionTags||[]).forEach(_tag=>{
            try {
              if(_tag === 'Irrelevant'){return;}
              if(!videosByID[card.infractionID]){return;}
              if(!byType[_tag]){ byType[_tag]={ //define a generic detail to hold the cards?
                
                set: [],
                type: 'playlist',
                classification: _tag,            
                count: 0,
                streamURL: 'playlist',
                journeyTime: null,
                };   
              }

              byType[_tag].set.push({
                InfractionID: card.infractionID,
                Tag: byType[_tag].count+1,
                TimeOffset: 0,
                streamURL: videosByID[card.infractionID],
                journeyTime: byInfractionID[card.infractionID].journeytime,
                reviewstatus: reviewerByInfractionID[card.infractionID].status,
                username: reviewerByInfractionID[card.infractionID].username,
              });
            
              //Add the card to the list:
              _cardsByType[_tag] = _cardsByType[_tag] || {};
              _cardsByType[_tag][card.infractionID] = card;
              byType[_tag].count +=1;

              //Duplicate the clip into the split Ingress/Egress types
              if(this.state.review24Hr){
                if(duplicatedTypeName.includes(_tag)){
                // if(_tag === 'Ingress' || _tag === 'Egress'){
                  _tag = _tag +'Clip'
                  //Add the card to the list:
                  _cardsByType[_tag] = _cardsByType[_tag] || {};
                  _cardsByType[_tag][card.infractionID] = card;
                }
              }
            } catch (error) {
              console.log("Failed  on card load: ",error,card);
            }
            
          })
  
        }
      });

      //Loop over all the returned clips
      if(this.state.review24Hr){
        for (const [classification_, set_] of Object.entries(clips)) {
          let typeName = classification_;
          if(duplicatedTypeName.includes(classification_)){
          // if(classification_==='Ingress' || classification_==='Egress'){
             typeName = classification_+'Clip'
            // continue;
          }
          if(!byType[typeName]){ byType[typeName]={ //define a generic detail to hold the cards?
            set: [],
            type: 'playlist',
            classification: typeName,            
            count: 0,
            streamURL: 'playlist',
            journeyTime: null,          
            };   
          }
  
          (set_||[]).forEach(clip_ => {
            if(classification_==='Ingress' || classification_==='Egress'){
              // console.log("Look at card: ",clip_,videosByID[clip_.infractionID]);
            }
            
            if(!videosByID[clip_.infractionID]){return;}
              
            byType[typeName].set.push({
              InfractionID: clip_.infractionID,
              Tag: byType[typeName].count+1,
              TimeOffset: 0,
              streamURL: videosByID[clip_.infractionID],
              journeyTime: byInfractionID[clip_.infractionID].journeytime,
              reviewstatus:clip_.reviewstatus,
              username:clip_.username,
              timerecorded: byInfractionID[clip_.infractionID].timerecorded,
            });            
            byType[typeName].count +=1;
          });
        }
      }

    } catch (error) {
      console.log("Failed to add clip? ",error);
    }
    // console.log("Bytype: ",byType)
    // console.log("By Type: ",byType);
    // return;

    //Declare a helper to sort the infraction clips based on the journeytime
    const timeCompare = (a, b) => {
      // console.log("Compare:" ,a,b)
      if (a.journeyTime && b.journeyTime) {
          
          if (a.journeyTime < b.journeyTime) { return -1;}
          return 1;
      }
      else{
        if (a.timerecorded && b.timerecorded) {
          if (moment(a.timerecorded) < moment(b.timerecorded)) { return -1;}
          return 1;
        }
      }
      return 0;
    };
    //Iterate over the types of the infractions and sort their lists:
    Object.values(byType||[]).forEach( type_ => {
      try{
        if(type_.set){
          // console.log("call compare?");
          type_.set = type_.set.sort(timeCompare);
          //reset the tags, use the index of the sorted array
          (type_.set||[]).forEach( (elem_,iIdx) => {
            // console.log("Elem: ",elem_,iIdx);
            elem_.Tag = iIdx+1; //the tag isn't 0 indexed, so add 1
          });
        }
      }catch(error){
      }
    });
    // console.log("Type: ",byType);

    const videos = {};
    // if(this.props.group=="drive_test_group" && !original_video){
    if(!original_video){
      original_video =highlights_video;
    }else{
      videos.original = original_video; // Original Video    
    }
    //Add the deleted flag to the videos object to pass to VideoReviewer
    videos.originalDeleted = originalDeleted;
    
      // We only want to show results if the results are complete, with a highlights video and
    // the original video
    //
    // But if there are no infractions, there won't be a highlights video, so we still want to
    // display that. In that case, we check that the video is set as Available before displaying
    let isAvailable = this.props.video && (this.props.video.status === VIDEO_STATUS.AVAILABLE);
    let should_show = original_video && (highlights_video || isAvailable);
    // 
    if(ejected_video){
      should_show = true;
    }

    //Should this change be allowed? Does it affect other videos to do this?
    if(this.props.video.status===VIDEO_STATUS.UNUSABLE && original_video){
      should_show = true;
    }

    if(this.props.video.vehicleType === '24Review'){
      bAllowReviewVideo = true;
    }

    // console.log("1",should_show,this.props.video.status)

    if(   this.props.video.status!==VIDEO_STATUS.UNUSABLE 
      &&  this.props.video.status!==VIDEO_STATUS.PROCESSING
      &&  this.props.video.status!==VIDEO_STATUS.PENDING
      ){
      // if(this.props.video.status!=='7'){
      videos.highlights = highlights_video; // Overall Highlights Video
      videos.byType = byType; // Infractions Videos by Infraction Type
      videos.clips = clips; // Highlight Clips, organized by Infraction Type
      videos.byID = videosByID; // Videos (streamURLs) by the InfractionID
      videos.byDriver = driverByID; // Videos (streamURLs) by the InfractionID
      videos.byReviewer = reviewerByInfractionID; //Reviewer details indexed by the InfractionID
      videos.byFlag = videosFlags;

      // console.log("VideoVH: ",videos);

       should_show = original_video || ejected_video || bAllowReviewVideo;
       if(highlights_video && highlights_video.metadata){should_show = should_show && (highlights_video.metadata.length - disabledCount) > 0;}
      //  console.log("Disable? ",highlights_video.metadata.length,disabledCount);

      // create empty dictionaries for the Video Cards for each Infraction Type
      Object.entries(videos.byType).forEach(([type, value]) => {
        value.cards = {};
      });

      // and then fill in the actual Video Cards into the appropriate containers
      _cards.forEach(card => {
        // console.log("Read card: ",card);
        const vid = videos.byType[card.infractionType.toLowerCase()];
        if (vid) {
          vid.cards[card.infractionID] = card;
        }
      });
    }
    // console.log("2",should_show)

    // let journeyPath = null;
    // let journeyEvents=[];
    // _journeyPromise.journey.forEach(journey => {
    //   if(journey.events){
    //     for (var i = 0; i < journey.events.length; i++) {
    //       let parts = journey.events[i].split(",");
    //       var obj={};
    //       obj.id = parts[0];
    //       obj.lat = parts[1];
    //       obj.lon = parts[2];
    //       journeyEvents.push(obj);
    //     }
    //   }
    //   journeyPath = journey.path;
    // });

    let reviewerName = null;
    // try {reviewerName = _listPromise.results[0].reviewerName} catch (error) { }
    // try {reviewerName = 'Jholmes'} catch (error) { }

    // console.log("List promise return: ",_listPromise);


    // // console.log("Videos: ",videos);
    // console.log("DriverInfo: ",_driverInfo);
    
    
    this.setState({
      // vid_info: data,
      loadTime: Date.now(),
      data_set: true,
      should_show: should_show,
      videos: videos,
      unpublished: unpublished > 0,
      video_card: null,
      cardsByType: _cardsByType,
      driver: '',
      reviewStatus: _reviewPromise.result,
      reviewerName: reviewerName,
    });

    //Required to support the withLoadingAnimation wrapper
    if(this.props.loadingComplete){this.props.loadingComplete(true);}
  }
  /*
  * @brief Called to update the data we're displaying
  */
  // updateData([data, cardResponse, driverInfo,journeyPromise,listPromise,reviewPromise]) {
    
  updateData([data, cardResponse, reviewPromise]) {
    // console.log("VH: ",this.props,data,cardResponse);
    //Handle the archived case:
    if(data.archived ){  
      if(data.timeavailable){ //object is being restored, show progress
        this.setState({ archived: 2,timeavailable:data.timeavailable});
        // this.setState({ archived: 1});
      }else{
        this.setState({ archived: 1});
      }
      return;
    }

    const cards = cardResponse.cards;    
    const cardsByType = {};
      // console.log("GroupConfig: ",this.props.groupconfig);
    cards.forEach(card => {
      card.timeReceived = moment(card.timeReceived);
      card.timeCreated = moment(card.timeCreated);
      if(card.infractionTags){ card.infractionTags = card.infractionTags.split(",");}
      else{card.infractionTags = []}
      // console.log("Card type: ",card.infractionType);
      card.name = (card.name || card.driverID || "DriverID: Unavailable").replace('_', ' ');
      card.timeOfDay = moment.parseZone(card.timeOfDay); //cast from string to moment object
      try {
        //Sort by the tab types:

        //Is it a driver facing
        if(this.props.groupconfig.driverInfractionSet.includes(card.infractionType) ){
          cardsByType[card.infractionType] = cardsByType[card.infractionType] || {};
          cardsByType[card.infractionType][card.infractionID] = card;
        //Is it a highlight tab
        }else if(this.props.groupconfig.infractionSet.includes(card.infractionType) ){

          cardsByType[card.infractionType] = cardsByType[card.infractionType] || {};
          cardsByType[card.infractionType][card.infractionID] = card;
        //Or is it the infraction tab:
        }else {
          for(const tag_ of card.infractionTags){
            cardsByType[tag_] = cardsByType[tag_] || {};
            cardsByType[tag_][card.infractionID] = card;
          }
        }  
      } catch (error) {
        
      }

      
    });
    // console.log("CardsByType: ",cardsByType);

    /*
    * Take the input list of highlights, which includes:
    * - Each Highlight Clip
    * - The Overall Highlights Video
    * - The Original Video
    * - The Infractions Videos (one for each Infraction Type represented)
    * 
    * and split them out into useful categorizations
    * 
    * See the comments on the 'videos' name below
    */

    //Handle data received from the file:
    if(data.file){
      axios.get(data.file)
      .then(res => {
          // console.log(res);
        this.processUpdateData(res.data,cards,cardsByType,reviewPromise);
      })
    }else{
      
      this.processUpdateData(data.highlights,cards,cardsByType,reviewPromise);
    }

    return;
  }

  /* @brief Callback when a card is updated
  */
  cardChange(_inCard){
    //  console.log("CardChange VH incard: ",_inCard)
    if(_inCard.timeCreated){ //is this a new card?
      this.setState(state => {
        const cardsByType = Object.assign({}, state.cardsByType);
        const thisType = Object.assign({}, cardsByType[_inCard.infractionType] || {});      
        
        thisType[_inCard.infractionID] = _inCard;
        cardsByType[_inCard.infractionType] = thisType;
        // console.log("CardChange2 VH: ",cardsByType)
        return {cardsByType: cardsByType};
      });
    }
    
    if(this.state.review24Hr){
      _inCard.review24 = true //add a flag to indicate that this is from 24hr review process, so we can audit it.
    }
    // window.setTimeout(() => { 
    //   try {
    //      that.child.current.updateButtons();    
    //   } catch (error) {
    //   }
    // }, 500);
    // console.log("CardTag: ",inCard,this.props.videos.byType);
    if(this.props.cardChange){this.props.cardChange(_inCard);}
  }

  /* @brief Callback when a tag is changed
  */
  tagChange(_inCard){
    
  }

  onArchiveDialogClose(){
    // console.log("Selected to restore: ");        
    let refreshPromise = this.getApiCall();
    refreshPromise.then(data => {
      this.updateData(data);
    });
  }

  
  /* @brief Callback when a tag is changed
  */
  updateVideos(_data){
    this.setState(state => {
      const videos = state.videos;
      const thisType = Object.assign({}, videos.byType[_data.toRemove] || {});
      if(thisType.set){ 
        //Remove from the video set
        // let foundButton2 = buttons.find(obj => obj.chosenName === _data.toRemove);
        thisType.set.splice(thisType.set.findIndex(item => item.InfractionID === _data.infractionID), 1)
        thisType.count =thisType.set.length;        
      }
      return {videos: videos}
    });
    
  }

  /*
  * @brief Called on the 'reject' button press, configures and calls the rejectHighlights lambda
  */
  rejectHighlights(){
    //set up the call to the publishing lambda
    Auth.currentSession().then(
      (auth) => {
        let apiName = "AuthLambda";
        let path = "/rejectHighlights";
        let params = {
          body: {
            token: auth.idToken.jwtToken,
            video: this.props.video.uniqueKey
          }//body of the message
        }  //end param struct
        API.post(apiName, path, params); //don't update the page after posting...?
        //.then(this.updateData);
      }//end auth block
    );
  }
  /*
  * @brief Called on the 'publish' button press, configures and calls the publishHighlights lambda
  */
  publishHighlights(){
    //set up the call to the publishing lambda
    Auth.currentSession().then(
      (auth) => {
        let apiName = "AuthLambda";
        let path = "/publishHighlights";
        let params = {
          body: {
            token: auth.idToken.jwtToken,
            video: this.props.video.uniqueKey
          }//body of the message
        }  //end param struct
        API.post(apiName, path, params); //don't update the page after posting...?
        //.then(this.updateData);
      }//end auth block
    );
  }

  /*
  * @brief Called to generate the visual content of the website
  */
  render() {
    const archived = this.state.archived;
    const got_data = this.state.data_set;
    const should_show = this.state.should_show;
    const timeavailable = this.state.timeavailable;
    let body = null;

    //If loading animation wrapper is in use, add the early return
    if(this.props.hideRender){return <div> Fetching video data, please wait </div>}

    // console.log("Show: ",should_show);
    if (should_show) {
      const parentID = this.props.video.simulated ?
                          this.props.video.uniqueKey + "-" + this.props.video.filename :
                          this.props.video.uniqueKey;
       body = <VideoReviewer key="vid-review" 
                            //  ref={this.child} 
                             video ={this.props.video}
                             parentVideo={this.props.video}
                             videoType = {'DVRREVIEW'}
                             
                             videos={this.state.videos}
                             reviewStatus={this.state.reviewStatus}
                             parentID={parentID} 
                             refreshList= { this.props.refreshHighlight }
                            //  onRefresh={this.refreshCallback}
                             cardChange={this.cardChange}                             
                             tagChange = {this.tagChange}
                             vidChange = {this.updateVideos}
                             filename={this.props.video && this.props.video.filename}
                             cardsByType={this.state.cardsByType}
                             
                             driver={this.state.driver}
                             filter={this.props.filter}
                             groupconfig= {this.props.groupconfig}
                             username = {this.state.username}
                             reviewerName = {this.state.reviewerName}
                             possibleFilters={this.props.possibleFilters}
                             review24Hr={this.state.review24Hr}
                            // review24Hr={false}
              />;
      
    } else if (got_data) {
      body = <div className="no-highlights-message">No highlights for this video</div>
    } else if (archived>0){
      let archivebody = null;
      let fileNameTitle =this.props.video.filename;
      if(this.props.groupconfig.group && this.props.groupconfig.group==="reviewgroup"){fileNameTitle = filenameAlphaStripper(this.props.video.filename);}
      //Switch the type of Archive message based on our state: 
      if(archived === 1){ //Do we need to choose to restore
        archivebody = <ArchiveDialog eventNotify={this.props.eventNotify} handleClose={this.onArchiveDialogClose} id={this.props.video.uniqueKey}/>
      }
      if(archived === 2){ //are we waiting to restore
        archivebody = <ArchivedRetrieval time={timeavailable}/>
      }

      body = <div>
          {this.props.video.filename && <h5 className="archived-filename">{fileNameTitle}</h5>}
          {archivebody}
      </div>
      
    }
    return (
      <div className="highlight-view">  
        {!this.state.loadTime && <div> Loading, please wait </div>}        
        {body}
      </div>
    );
  }
}


export { VideoHighlight };
